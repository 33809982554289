import React from "react";

import * as Styled from "./styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
//import { Controller } from "react-hook-form";
import { ComplementData } from "shared/mock";
import { useFirstConsult } from "shared/providers/FirstConsult";
import toast from "react-hot-toast";
import { postDiagnosis } from "requests/Diagnosis";
import { useAuth } from "shared/providers/auth";

//import { TagInput } from "rsuite";

const Diagnosis: React.FC<{ control: any }> = ({ control }) => {
  const {
    setStep,
    stepDiagnosis,
    setStepDiagnosis,
    btnCirurgy,
    setBtnCirurgy,
    btnOrtognatica,
    setBtnOrtognatica,
    btnOthers,
    btnPatients,
    setBtnOthers,
    setBtnPatients,
    brace,
    setBrace,
    checkedState,
    handleCheckboxChange,
    setDiagnosisDescription,
    //diagnosisDescription,
    patientObj,
    setObsDiagnosis,
    setNoCirurgyDiagnosis,
    noCirurgyDiagnosis,
    setCheckedState,
    preOptions,
    selectedTags,
    setSelectedTags,
    obsDiagnosis,
    //modelInvisible,
    //setModelInvisible,
  } = useFirstConsult();

  const { user } = useAuth();

  const clearTreatment = () => {
    setCheckedState({
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
    });
  };

  const next = async () => {
    setDiagnosisDescription(selectedTags.join(", "));

    if (selectedTags.length < 1 && obsDiagnosis.length < 1) {
      toast.error("Preencha o campo observações e tente novamente");
      return false;
    }

    /*if (selectedTags.join(", ").length < 20) {
      toast.error("Inserir descrição com no mínimo 20 caracteres");
      return false;
    }*/

    if (btnCirurgy === "NOT") {
      toast.error("Responde as perguntas e tente novamente");
      return false;
    }

    if (btnOthers === "YES") {
      const exists = Object.values(checkedState).includes(true);

      if (!exists) {
        toast.error("Selecione um Tratamento Complementar");
        return false;
      }
    }

    if (noCirurgyDiagnosis) {
      setStep(6);
      return false;
    }

    if (btnCirurgy === "YES" && btnOrtognatica === "NOT") {
      toast.error("Responde as perguntas e tente novamente");
      return false;
    }

    if (brace === 0 && btnPatients !== "NO") {
      toast.error("Selecione o Tipo de Aparelho");
      return false;
    }

    /*if (brace === 3 && modelInvisible === "") {
      toast.error("Selecione o Modelo");
      return false;
    }*/

    const patientData = localStorage.getItem("personAttendance");

    if (patientData) {
      const patient = JSON.parse(patientData);
      patient.type = brace;
      localStorage.setItem("personAttendance", JSON.stringify(patient));
    }

    if (btnPatients !== "NO") {
      const { status } = await postDiagnosis({
        id: user.data.id,
        idPac: patientObj.pac_id,
        type: brace,
      });

      if ([200, 201].includes(status)) {
        if (stepDiagnosis > 3) {
          setStep(2);
        }
      }
    }

    setStep(2);
  };

  // Função para adicionar/remover tags selecionadas
  const checkBoxChange = (name: string, isChecked: boolean) => {
    setSelectedTags((prevTags: any[]) =>
      isChecked ? [...prevTags, name] : prevTags.filter((tag) => tag !== name)
    );
  };

  // Função para lidar com a alteração no TagInput
  /* const handleTagInputChange = (value: readonly string[]) => {
    setSelectedTags([...value]); // Atualiza `selectedTags` com o novo valor
  };*/

  return (
    <Styled.Box>
      <Styled.BoxDescription>
        <h5>Diagnóstico e Plano de tratamento</h5>
        <h5 style={{ color: "#556370", marginTop: "15px" }}>Pré-avaliação</h5>
        <Styled.AriaBox>
          {preOptions.map((item) => (
            <FormControlLabel
              key={item.id}
              control={
                <Checkbox
                  checked={selectedTags.includes(item.name)}
                  onChange={(event) =>
                    checkBoxChange(item.name, event.target.checked)
                  }
                />
              }
              label={item.name}
            />
          ))}
        </Styled.AriaBox>

        {/* 
        <Controller
          control={control}
          name="diagnosis"
          render={({ field: { ...field } }) => (
            <TagInput
              {...field}
              value={selectedTags}
              onChange={handleTagInputChange}
              placeholder="Selecione ou digite..."
              size="lg"
              style={{
                padding: "14.5px 12px",
                color: "rgba(0, 0, 0, 0.87)",
                border: "1px solid rgba(0, 0, 0, 0.23)",
              }}
            />
          )}
        />
       */}
      </Styled.BoxDescription>

      {/*Caso para cirurgia ortognática? */}
      <Styled.BoxDescription style={{ padding: "15px 0", gap: "20px" }}>
        <h5 style={{ color: "#556370" }}>Caso para cirurgia ortognática?</h5>
        <Styled.BoxButtons>
          <Button
            variant={btnCirurgy === "NO" ? "contained" : "outlined"}
            onClick={() => {
              setBtnCirurgy("NO");
              setBtnOrtognatica("NOT");
              setBtnOthers("NOT");
              setBtnPatients("NOT");
              setStepDiagnosis(2);
              setBrace(0);
              clearTreatment();
            }}
          >
            NÂO
          </Button>
          <Button
            variant={btnCirurgy === "YES" ? "contained" : "outlined"}
            onClick={() => {
              setBtnCirurgy("YES");
              setBtnOrtognatica("NOT");
              setBtnOthers("NOT");
              setBtnPatients("NOT");
              setStepDiagnosis(2);
              setBrace(0);
              clearTreatment();
            }}
          >
            SIM
          </Button>
        </Styled.BoxButtons>
      </Styled.BoxDescription>

      {/*Caso para cirurgia ortognática? = Sim */}
      {btnCirurgy === "YES" && (
        <Styled.BoxDescription style={{ padding: "15px 0", gap: "20px" }}>
          <h5 style={{ color: "#556370" }}>
            Possível tratamento sem Cirurgia Ortognática?
          </h5>
          <Styled.BoxButtons>
            <Button
              variant={btnOrtognatica === "NO" ? "contained" : "outlined"}
              onClick={() => {
                setBtnOrtognatica("NO");
                setBrace(0);
                clearTreatment();
              }}
            >
              NÂO
            </Button>
            <Button
              variant={btnOrtognatica === "YES" ? "contained" : "outlined"}
              onClick={() => {
                setBtnOrtognatica("YES");
                setBrace(0);
                clearTreatment();
              }}
            >
              SIM
            </Button>
          </Styled.BoxButtons>
        </Styled.BoxDescription>
      )}

      {/*Possível tratamento sem Cirurgia Ortognática? = Não */}
      {btnOrtognatica === "NO" && (
        <Styled.BoxDescription style={{ padding: "15px 0", gap: "20px" }}>
          <h5 style={{ color: "#556370" }}>Paciente realizará a cirurgia?</h5>
          <Styled.BoxButtons>
            <Button
              variant={btnPatients === "NO" ? "contained" : "outlined"}
              onClick={() => {
                setBtnPatients("NO");
                setBrace(0);
                setNoCirurgyDiagnosis(false);
                clearTreatment();
              }}
            >
              NÃO
            </Button>
            <Button
              variant={btnPatients === "YES" ? "contained" : "outlined"}
              onClick={() => {
                setBtnPatients("YES");
                setBrace(0);
                setNoCirurgyDiagnosis(true);
                clearTreatment();
              }}
            >
              SIM
            </Button>
          </Styled.BoxButtons>
        </Styled.BoxDescription>
      )}

      {/*Paciente realizará a cirurgia? = Não */}
      {/*btnPatients === "NO" && "FICHA CLÍNICA"*/}

      {/*Caso para cirurgia ortognática? = Não Ou Possível tratamento sem Cirurgia Ortognática? = Sim OU Paciente realizará a cirurgia? = Sim */}
      {btnCirurgy === "NO" ||
      btnOrtognatica === "YES" ||
      btnPatients === "YES" ? (
        <Styled.BoxDescription style={{ padding: "15px 0", gap: "20px" }}>
          <h5 style={{ color: "#556370" }}>
            Serão necessários outros tratamentos para complementar o tratamento
            ortodôntico?
          </h5>
          <Styled.BoxButtons>
            <Button
              variant={btnOthers === "NO" ? "contained" : "outlined"}
              onClick={() => {
                setBtnOthers("NO");
                setStepDiagnosis(4);
                setBrace(0);
                clearTreatment();
              }}
            >
              NÂO
            </Button>
            <Button
              variant={btnOthers === "YES" ? "contained" : "outlined"}
              onClick={() => {
                setBtnOthers("YES");
                setBrace(0);
                setStepDiagnosis(3);
                clearTreatment();
              }}
            >
              SIM
            </Button>
          </Styled.BoxButtons>
        </Styled.BoxDescription>
      ) : (
        ""
      )}

      {btnOthers === "YES" && (
        <Styled.BoxIndication>
          <Typography variant="h5" sx={{ color: "#556370", marginTop: "10px" }}>
            Tratamento Complementar
          </Typography>

          <Styled.ContentComplement>
            {ComplementData.map((item, index) => (
              <Styled.AllComplements>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedState[index] || false}
                        onClick={() => {
                          handleCheckboxChange(index);
                          setStepDiagnosis(4);
                        }}
                      />
                    }
                    label={item}
                  />
                </FormGroup>
              </Styled.AllComplements>
            ))}
          </Styled.ContentComplement>
        </Styled.BoxIndication>
      )}
      {stepDiagnosis > 3 && (
        <Styled.BraceType>
          <Button
            sx={{ width: "45%", padding: "20px 10px" }}
            className={brace === 2 ? "activeBtn" : "disabledBtn"}
            onClick={() => setBrace(2)}
          >
            APARELHO MÓVEL/COMPLEMENTAR
          </Button>
          <Button
            sx={{ width: "45%", padding: "20px 10px" }}
            className={brace === 1 ? "activeBtn" : "disabledBtn"}
            onClick={() => setBrace(1)}
          >
            APARELHO FIXO
          </Button>

          <Button
            sx={{ width: "45%", padding: "20px 10px" }}
            className={brace === 3 ? "activeBtn" : "disabledBtn"}
            onClick={() => setBrace(3)}
          >
            APARELHO INVISÍVEL
          </Button>
        </Styled.BraceType>
      )}

      {/*brace === 3 && (
        <Styled.BoxDescription>
          <Typography variant="h5" sx={{ color: "#556370", marginTop: "10px" }}>
            Qual tipo de modalidade?
          </Typography>

          <Styled.GridModal>
            <Button
              className={
                modelInvisible === "express" ? "activeBtn" : "disabledBtn"
              }
              onClick={() => setModelInvisible("express")}
            >
              Express
            </Button>
            <Button
              className={
                modelInvisible === "lite" ? "activeBtn" : "disabledBtn"
              }
              onClick={() => setModelInvisible("lite")}
            >
              Lite
            </Button>
            <Button
              className={
                modelInvisible === "moderate" ? "activeBtn" : "disabledBtn"
              }
              onClick={() => setModelInvisible("moderate")}
            >
              Moderate
            </Button>
            <Button
              className={
                modelInvisible === "comprehensive" ? "activeBtn" : "disabledBtn"
              }
              onClick={() => setModelInvisible("comprehensive")}
            >
              Comprehensive
            </Button>
          </Styled.GridModal>
        </Styled.BoxDescription>
      )*/}

      <Styled.BoxDescription>
        <TextField
          label="Observações"
          onChange={(e) => setObsDiagnosis(e.target.value)}
          defaultValue={obsDiagnosis}
        />
      </Styled.BoxDescription>

      <Styled.BtnFixed>
        <Button fullWidth onClick={next}>
          Continuar
        </Button>
      </Styled.BtnFixed>
    </Styled.Box>
  );
};

export { Diagnosis };
