import React from "react";
import { questions } from "shared/data/question";
import { ComplementData } from "shared/mock";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { SelectChangeEvent } from "@mui/material";
import { Schedule } from "shared/types/Schedule";
import { IOdont } from "shared/types/SendSchedule";
import { IAnamnese } from "shared/types/Anamnese";
import { getClinicalRecords } from "requests/ClinicalRecord";
import { useAuth } from "../auth";
import { NextAppointment } from "shared/types/ClinicalRecord";
import { ILabelValue } from "shared/types/Auth";

interface IProps {
  children: any;
}

export interface IMaintenanceContext {
  step: number;
  setStep: (value: number) => void;
  patientObj: Schedule;

  //Anamnese
  selectedOptions: IAnamnese[];
  handleOptionChange: (value: IAnamnese) => void;
  currentQuestionIndex: number;
  answers: { [key: string]: string };
  handleAnswerChange: (questionText: string, answer: string) => void;
  observations: { [key: string]: string };
  errors: { [key: string]: string };
  handleObservationChange: (questionText: string, observation: string) => void;

  //Diagnosis
  stepDiagnosis: number;
  setStepDiagnosis: (value: number) => void;
  btnCirurgy: "YES" | "NO" | "NOT";
  setBtnCirurgy: (value: "YES" | "NO" | "NOT") => void;
  btnOrtognatica: "YES" | "NO" | "NOT";
  setBtnOrtognatica: (value: "YES" | "NO" | "NOT") => void;
  btnOthers: "YES" | "NO" | "NOT";
  setBtnOthers: (value: "YES" | "NO" | "NOT") => void;
  btnPatients: "YES" | "NO" | "NOT";
  setBtnPatients: (value: "YES" | "NO" | "NOT") => void;
  braceComplement: boolean;
  setBraceComplement: (value: boolean) => void;
  brace: 0 | 1 | 2;
  setBrace: (value: 0 | 1 | 2) => void;
  checkedState: { [key: number]: boolean };
  handleCheckboxChange: (value: number) => void;
  selectedDate: dayjs.Dayjs | null;
  selectedTime: string;
  selectedHour: string;
  handleDateChange: (value: dayjs.Dayjs | null) => void;
  handleTimeChange: (value: SelectChangeEvent) => void;
  handleHourChange: (value: SelectChangeEvent) => void;
  othersProcedures: boolean;
  setOthersProcedures: (value: boolean) => void;
  othersReferrals: boolean;
  setOthersReferrals: (value: boolean) => void;
  regionDescription: string;
  setRegionDescription: (value: string) => void;
  valueOthers: any;
  setValueOthers: (value: any) => void;
  valueEnc: any;
  setValueEnc: (value: any) => void;
  aparate: boolean;
  setAparate(value: boolean): void;
  wire: boolean;
  setWire: (value: boolean) => void;
  keepThread: boolean;
  setKeepThread: (value: boolean) => void;
  ciment: boolean;
  setCiment: (value: boolean) => void;
  glue: boolean;
  setGlue: (value: boolean) => void;
  complement: boolean;
  setComplement: (value: boolean) => void;
  rep: boolean;
  setRep: (value: boolean) => void;
  aparateBrace: string[];
  setAparateBrace: (value: string[]) => void;
  changeWire: string[];
  setChangeWire: (value: string[]) => void;
  keep: string[];
  setKeep: (value: string[]) => void;
  cimentBand: string[];
  setCimentBand: (value: string[]) => void;
  glueTube: string[];
  setGlueTube: (value: string[]) => void;
  comp: ILabelValue[];
  setComp: (value: ILabelValue[]) => void;
  repOrRec: string[];
  setRepOrRec: (value: string[]) => void;
  repOrRecBrace: string[];
  setRepOrRecBrace: (value: string[]) => void;
  needsBand: "YES" | "NO" | "NOT";
  setNeedsBand: (value: "YES" | "NO" | "NOT") => void;
  needsSeparator: "YES" | "NO" | "NOT";
  setNeedsSeparator: (value: "YES" | "NO" | "NOT") => void;
  performMolding: "YES" | "NO" | "NOT";
  setPerformMolding: (value: "YES" | "NO" | "NOT") => void;
  installSeparator: "YES" | "NO" | "NOT";
  setInstallSeparator: (value: "YES" | "NO" | "NOT") => void;
  moldingJustification: string;
  setMoldingJustification: (value: string) => void;
  separatorJustification: string;
  setSeparatorJustification: (value: string) => void;
  braceType: "NOT" | "UPPER" | "LOWER";
  setBraceType: (value: "NOT" | "UPPER" | "LOWER") => void;
  painPrescriptionNeeded: "NOT" | "NO" | "YES";
  setPainPrescriptionNeeded: (value: "NOT" | "NO" | "YES") => void;
  informedBasicCare: "NOT" | "NO" | "YES";
  setInformedBasicCare: (value: "NOT" | "NO" | "YES") => void;
  deliveredTreatmentManual: "NOT" | "NO" | "YES";
  setDeliveredTreatmentManual: (value: "NOT" | "NO" | "YES") => void;
  advisedWireAndWax: "NOT" | "NO" | "YES";
  setAdvisedWireAndWax: (value: "NOT" | "NO" | "YES") => void;
  additionalDevice: any;
  setAdditionalDevice: (value: any) => void;
  hasComplement: boolean;
  setHasComplement: (value: boolean) => void;
  hasOtherProcedures: boolean;
  setHasOtherProcedures: (value: boolean) => void;
  hasOtherReferrals: boolean;
  setHasOtherReferrals: (value: boolean) => void;
  otherProceduresValue: any;
  setOtherProceduresValue: (value: any) => void;
  referralValue: any;
  setReferralValue: (value: any) => void;
  regionDescriptionProcedure: string;
  setRegionDescriptionProcedure: (value: string) => void;
  showPainPrescriptionNeeded: boolean;
  setShowPainPrescriptionNeeded: (value: boolean) => void;
  showInformedBasicCare: boolean;
  setShowInformedBasicCare: (value: boolean) => void;
  showDeliveredTreatmentManual: boolean;
  setShowDeliveredTreatmentManual: (value: boolean) => void;
  showAdvisedWireAndWax: boolean;
  setShowAdvisedWireAndWax: (value: boolean) => void;
  showAdditionalDevice: boolean;
  setShowAdditionalDevice: (value: boolean) => void;
  diagnosisDescription: string;
  setDiagnosisDescription: (value: string) => void;
  materialProcedure: string;
  setMaterialProcedure: (value: string) => void;
  numberProcedure: string;
  setNumberProcedure: (value: string) => void;
  othersProceduresFixed: string;
  setOthersProceduresFixed: (value: string) => void;
  materialCola: string;
  setMaterialCola: (value: string) => void;
  numberCola: string;
  setNumberCola: (value: string) => void;
  othersCola: string;
  setOthersCola: (value: string) => void;

  materialColaInf: string;
  setMaterialColaInf: (value: string) => void;
  numberColaInf: string;
  setNumberColaInf: (value: string) => void;
  othersColaInf: string;
  setOthersColaInf: (value: string) => void;

  materialChange: string;
  setMaterialChange: (value: string) => void;
  numberChange: string;
  setNumberChange: (value: string) => void;
  othersChange: string;
  setOthersChange: (value: string) => void;

  materialChangeInf: string;
  setMaterialChangeInf: (value: string) => void;
  numberChangeInf: string;
  setNumberChangeInf: (value: string) => void;
  othersChangeInf: string;
  setOthersChangeInf: (value: string) => void;

  ativation: string;
  setAtivation: (value: string) => void;
  odontogramaComplement: IOdont[];
  setOdontogramaComplement: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaCollage: IOdont[];
  setOdontogramaCollage: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaCiment: IOdont[];
  setOdontogramaCiment: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaAparat: IOdont[];
  setOdontogramaAparat: (value: (prev: IOdont[]) => IOdont[]) => void;

  bestTime: string;
  setBestTime: (value: string) => void;
  setSelectedHour: (value: string) => void;
  videoClicked: boolean;
  setVideoClicked: (value: boolean) => void;
  painPrescriptionObservations: string;
  setPainPrescriptionObservations: (value: string) => void;
  basicCareObservations: string;
  setBasicCareObservations: (value: string) => void;
  treatmentManualObservations: string;
  setTreatmentManualObservations: (value: string) => void;
  wireAndWaxObservations: string;
  setWireAndWaxObservations: (value: string) => void;
  errorPainPrescription: boolean;
  setErrorPainPrescription: (value: boolean) => void;
  errorBasicCare: boolean;
  setErrorBasicCare: (value: boolean) => void;
  errorTreatmentManual: boolean;
  setErrorTreatmentManual: (value: boolean) => void;
  errorWireAndWax: boolean;
  setErrorWireAndWax: (value: boolean) => void;
  questionsPlanning: NextAppointment[];

  aparateReturn: boolean;
  setAparateReturn: (value: boolean) => void;
  aparateBraceReturn: string[];
  setAparateBraceReturn: (value: string[]) => void;
  materialReturn: string;
  setMaterialReturn: (value: string) => void;
  numberReturn: string;
  setNumberReturn: (value: string) => void;
  othersReturn: string;
  setOthersReturn: (value: string) => void;

  materialReturnInf: string;
  setMaterialReturnInf: (value: string) => void;
  numberReturnInf: string;
  setNumberReturnInf: (value: string) => void;
  othersReturnInf: string;
  setOthersReturnInf: (value: string) => void;

  wireReturn: boolean;
  setWireReturn: (value: boolean) => void;
  changeWireReturn: string[];
  setChangeWireReturn: (value: string[]) => void;
  materialChangeReturn: string;
  setMaterialChangeReturn: (value: string) => void;
  numberChangeReturn: string;
  setNumberChangeReturn: (value: string) => void;
  othersChangeReturn: string;
  setOthersChangeReturn: (value: string) => void;

  materialChangeReturnInf: string;
  setMaterialChangeReturnInf: (value: string) => void;
  numberChangeReturnInf: string;
  setNumberChangeReturnInf: (value: string) => void;
  othersChangeReturnInf: string;
  setOthersChangeReturnInf: (value: string) => void;

  keepThreadReturn: boolean;
  setKeepThreadReturn: (value: boolean) => void;
  keepReturn: string[];
  setKeepReturn: (value: string[]) => void;
  ativationReturn: string;
  setAtivationReturn: (value: string) => void;

  cimentReturn: boolean;
  setCimentReturn: (value: boolean) => void;
  cimentBandReturn: string[];
  setCimentBandReturn: (value: string[]) => void;

  glueReturn: boolean;
  setGlueReturn: (value: boolean) => void;
  glueTubReturn: string[];
  setGlueTubReturn: (value: string[]) => void;

  repReturn: boolean;
  setRepReturn: (value: boolean) => void;
  repOrRecReturn: "NOT" | "REP" | "REC";
  setRepOrRecReturn: (value: "NOT" | "REP" | "REC") => void;
  repOrRecBraceReturn: "NOT" | "SUP" | "INF";
  setRepOrRecBraceReturn: (value: "NOT" | "SUP" | "INF") => void;

  complementReturn: boolean;
  setComplementReturn: (value: boolean) => void;
  compReturn: any;
  setCompReturn: (value: any) => void;

  othersProceduresReturn: boolean;
  setOthersProceduresReturn: (value: boolean) => void;
  valueOthersReturn: any;
  setValueOthersReturn: (value: any) => void;

  othersReferralsReturn: boolean;
  setOthersReferralsReturn: (value: boolean) => void;
  valueEncReturn: any;
  setValueEncReturn: (value: any) => void;

  mostImportantType: string;
  setMostImportantType: (value: string) => void;
  bandaExists: boolean;
  setBandaExists: (value: boolean) => void;
  bandaId: number;
  setBandaId: (value: number) => void;
  selectedProceduresId: number;
  setSelectedProceduresId: (value: number) => void;

  selectedDateBand: dayjs.Dayjs | null;
  selectedTimeBand: string;
  selectedHourBand: string;
  handleDateChangeBand: (value: dayjs.Dayjs | null) => void;
  handleTimeChangeBand: (value: SelectChangeEvent) => void;
  handleHourChangeBand: (value: SelectChangeEvent) => void;

  onlyBand: boolean;
  setOnlyBand: (value: boolean) => void;

  separatorId: number;
  setSeparatorId: (value: number) => void;

  justify01: string;
  setJustify01: (value: string) => void;
  justify02: string;
  setJustify02: (value: string) => void;
  justify03: string;
  setJustify03: (value: string) => void;
  justify04: string;
  setJustify04: (value: string) => void;
  justify05: string;
  setJustify05: (value: string) => void;
  justify06: string;
  setJustify06: (value: string) => void;
  justify07: string;
  setJustify07: (value: string) => void;
  justify08: string;
  setJustify08: (value: string) => void;
  justify09: string;
  setJustify09: (value: string) => void;

  informateRegion: string;
  setInformateRegion: (value: string) => void;

  realize: "NO" | "NOT" | "YES";
  setRealize: (value: "NO" | "NOT" | "YES") => void;
  justification: string;
  setJustification: (value: string) => void;

  realizeContent: "NO" | "NOT" | "YES";
  setRealizeContent: (value: "NO" | "NOT" | "YES") => void;
  justificationContent: string;
  setJustificationContent: (value: string) => void;
  informateRegionContent: string;
  setInformateRegionContent: (value: string) => void;

  realizeAparat: "NO" | "NOT" | "YES";
  setRealizeAparat: (value: "NO" | "NOT" | "YES") => void;
  justificationAparat: string;
  setJustificationAparat: (value: string) => void;

  realizeEruption: "NO" | "NOT" | "YES";
  setRealizeEruption: (value: "NO" | "NOT" | "YES") => void;
  justificationEruption: string;
  setJustificationEruption: (value: string) => void;
  informateRegionEruption: string;
  setInformateRegionEruption: (value: string) => void;

  realizeIntermaxilary: "NO" | "NOT" | "YES";
  setRealizeIntermaxilary: (value: "NO" | "NOT" | "YES") => void;
  justificationIntermaxilary: string;
  setJustificationIntermaxilary: (value: string) => void;

  realizeCollage: "NO" | "NOT" | "YES";
  setRealizeCollage: (value: "NO" | "NOT" | "YES") => void;
  justificationCollage: string;
  setJustificationCollage: (value: string) => void;

  realizeRebonding: "NO" | "NOT" | "YES";
  setRealizeRebonding: (value: "NO" | "NOT" | "YES") => void;
  justificationRebonding: string;
  setJustificationRebonding: (value: string) => void;

  realizeBite: "NO" | "NOT" | "YES";
  setRealizeBite: (value: "NO" | "NOT" | "YES") => void;
  justificationBite: string;
  setJustificationBite: (value: string) => void;

  route: "NO" | "NOT" | "YES";
  setRoute: (value: "NO" | "NOT" | "YES") => void;
  justifyRoute: string;
  setJustifyRoute: (value: string) => void;

  realizeRelease: "NO" | "NOT" | "YES";
  setRealizeRelease: (value: "NO" | "NOT" | "YES") => void;
  justificationRelease: string;
  setJustificationRelease: (value: string) => void;

  currentProcedureIndex: number;
  setCurrentProcedureIndex: (value: number) => void;

  odontogramaCollageReturn: IOdont[];
  setOdontogramaCollageReturn: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaCimentReturn: IOdont[];
  setOdontogramaCimentReturn: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaComplementReturn: IOdont[];
  setOdontogramaComplementReturn: (value: (prev: IOdont[]) => IOdont[]) => void;

  proceduresLastAttendance: string[];
  setProceduresLastAttendance: (value: string[]) => void;
  currentProcedureIndexReturn: number;
  setCurrentProcedureIndexReturn: (value: number) => void;

  showQuestionsReturn: boolean;
  setShowQuestionsReturn: (value: boolean) => void;

  odontogramaRemote: IOdont[];
  setOdontogramaRemote: (value: (prev: IOdont[]) => IOdont[]) => void;
  odontogramaInstall: IOdont[];
  setOdontogramaInstall: (value: (prev: IOdont[]) => IOdont[]) => void;
  odontogramaButton: IOdont[];
  setOdontogramaButton: (value: (prev: IOdont[]) => IOdont[]) => void;
  odontogramaIntermax: IOdont[];
  setOdontogramaIntermax: (value: (prev: IOdont[]) => IOdont[]) => void;
  odontogramaLevant: IOdont[];
  setOdontogramaLevant: (value: (prev: IOdont[]) => IOdont[]) => void;

  informateRegionReturn: string;
  setInformateRegionReturn: (value: string) => void;

  realizeReturn: "NO" | "NOT" | "YES";
  setRealizeReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationReturn: string;
  setJustificationReturn: (value: string) => void;

  realizeContentReturn: "NO" | "NOT" | "YES";
  setRealizeContentReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationContentReturn: string;
  setJustificationContentReturn: (value: string) => void;
  informateRegionContentReturn: string;
  setInformateRegionContentReturn: (value: string) => void;

  realizeAparatReturn: "NO" | "NOT" | "YES";
  setRealizeAparatReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationAparatReturn: string;
  setJustificationAparatReturn: (value: string) => void;

  realizeEruptionReturn: "NO" | "NOT" | "YES";
  setRealizeEruptionReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationEruptionReturn: string;
  setJustificationEruptionReturn: (value: string) => void;
  informateRegionEruptionReturn: string;
  setInformateRegionEruptionReturn: (value: string) => void;

  realizeIntermaxilaryReturn: "NO" | "NOT" | "YES";
  setRealizeIntermaxilaryReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationIntermaxilaryReturn: string;
  setJustificationIntermaxilaryReturn: (value: string) => void;

  realizeCollageReturn: "NO" | "NOT" | "YES";
  setRealizeCollageReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationCollageReturn: string;
  setJustificationCollageReturn: (value: string) => void;

  realizeRebondingReturn: "NO" | "NOT" | "YES";
  setRealizeRebondingReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationRebondingReturn: string;
  setJustificationRebondingReturn: (value: string) => void;

  realizeBiteReturn: "NO" | "NOT" | "YES";
  setRealizeBiteReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationBiteReturn: string;
  setJustificationBiteReturn: (value: string) => void;

  routeReturn: "NO" | "NOT" | "YES";
  setRouteReturn: (value: "NO" | "NOT" | "YES") => void;
  justifyRouteReturn: string;
  setJustifyRouteReturn: (value: string) => void;

  realizeReleaseReturn: "NO" | "NOT" | "YES";
  setRealizeReleaseReturn: (value: "NO" | "NOT" | "YES") => void;
  justificationReleaseReturn: string;
  setJustificationReleaseReturn: (value: string) => void;

  odontogramaRemoteReturn: IOdont[];
  setOdontogramaRemoteReturn: (value: (prev: IOdont[]) => IOdont[]) => void;
  odontogramaInstallReturn: IOdont[];
  setOdontogramaInstallReturn: (value: (prev: IOdont[]) => IOdont[]) => void;
  odontogramaButtonReturn: IOdont[];
  setOdontogramaButtonReturn: (value: (prev: IOdont[]) => IOdont[]) => void;
  odontogramaIntermaxReturn: IOdont[];
  setOdontogramaIntermaxReturn: (value: (prev: IOdont[]) => IOdont[]) => void;
  odontogramaLevantReturn: IOdont[];
  setOdontogramaLevantReturn: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaPlanningAparat: IOdont[];
  setOdontogramaPlanningAparat: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaPlanningAparatReturn: IOdont[];
  setOdontogramaPlanningAparatReturn: (
    value: (prev: IOdont[]) => IOdont[]
  ) => void;

  braceTypeMaintenance: "NOT" | "UPPER" | "LOWER";
  setBraceTypeMaintenance: (value: "NOT" | "UPPER" | "LOWER") => void;

  repOrRecProcedure: "NOT" | "REP" | "REC";
  setRepOrRecProcedure: (value: "NOT" | "REP" | "REC") => void;
  repOrRecBraceProcedure: "NOT" | "SUP" | "INF";
  setRepOrRecBraceProcedure: (value: "NOT" | "SUP" | "INF") => void;
  repProcedure: boolean;
  setRepProcedure: (value: boolean) => void;

  painPrescriptionNeededMaintenance: "NOT" | "NO" | "YES";
  setPainPrescriptionNeededMaintenance: (value: "NOT" | "NO" | "YES") => void;

  informedBasicCareMaintenance: "NOT" | "NO" | "YES";
  setInformedBasicCareMaintenance: (value: "NOT" | "NO" | "YES") => void;
  basicCareObservationsMaintenance: string;
  setBasicCareObservationsMaintenance: (value: string) => void;

  materialProcedureMaintenance: string;
  setMaterialProcedureMaintenance: (value: string) => void;
  numberProcedureMaintenance: string;
  setNumberProcedureMaintenance: (value: string) => void;
  othersProceduresFixedMaintenance: string;
  setOthersProceduresFixedMaintenance: (value: string) => void;

  braceTypeMaintenanceReturn: "NOT" | "UPPER" | "LOWER";
  setBraceTypeMaintenanceReturn: (value: "NOT" | "UPPER" | "LOWER") => void;

  repOrRecProcedureReturn: "NOT" | "REP" | "REC";
  setRepOrRecProcedureReturn: (value: "NOT" | "REP" | "REC") => void;
  repOrRecBraceProcedureReturn: "NOT" | "SUP" | "INF";
  setRepOrRecBraceProcedureReturn: (value: "NOT" | "SUP" | "INF") => void;
  repProcedureReturn: boolean;
  setRepProcedureReturn: (value: boolean) => void;

  painPrescriptionNeededMaintenanceReturn: "NOT" | "NO" | "YES";
  setPainPrescriptionNeededMaintenanceReturn: (
    value: "NOT" | "NO" | "YES"
  ) => void;

  informedBasicCareMaintenanceReturn: "NOT" | "NO" | "YES";
  setInformedBasicCareMaintenanceReturn: (value: "NOT" | "NO" | "YES") => void;
  basicCareObservationsMaintenanceReturn: string;
  setBasicCareObservationsMaintenanceReturn: (value: string) => void;

  materialProcedureMaintenanceReturn: string;
  setMaterialProcedureMaintenanceReturn: (value: string) => void;
  numberProcedureMaintenanceReturn: string;
  setNumberProcedureMaintenanceReturn: (value: string) => void;
  othersProceduresFixedMaintenanceReturn: string;
  setOthersProceduresFixedMaintenanceReturn: (value: string) => void;

  odontogramaSeparator: IOdont[];
  setOdontogramaSeparator: (value: (prev: IOdont[]) => IOdont[]) => void;
  braceMovel: boolean;
  setBraceMovel: (value: boolean) => void;
  relizeMoldage: boolean;
  setRealizeMoldage: (value: boolean) => void;

  odontogramaRepOrRec: IOdont[];
  setOdontogramaRepOrRec: (value: (prev: IOdont[]) => IOdont[]) => void;

  showOtherProcedureScreen: boolean;
  setShowOtherProcedureScreen: (value: boolean) => void;

  hoursTime: string[];
  setHoursTime: (value: string[]) => void;

  wireProcedures: boolean;
  setWireProcedures: (value: boolean) => void;
  changeWireProcedures: string[];
  setChangeWireProcedures: (value: string[]) => void;
  materialChangeProcedures: string;
  setMaterialChangeProcedures: (value: string) => void;
  numberChangeProcedures: string;
  setNumberChangeProcedures: (value: string) => void;
  othersChangeProcedures: string;
  setOthersChangeProcedures: (value: string) => void;

  materialChangeProceduresInf: string;
  setMaterialChangeProceduresInf: (value: string) => void;
  numberChangeProceduresInf: string;
  setNumberChangeProceduresInf: (value: string) => void;
  othersChangeProceduresInf: string;
  setOthersChangeProceduresInf: (value: string) => void;

  keepThreadProcedures: boolean;
  setKeepThreadProcedures: (value: boolean) => void;
  keepProcedures: string[];
  setKeepProcedures: (value: string[]) => void;
  ativationProcedures: string;
  setAtivationProcedures: (value: string) => void;

  bestTimeBand: string;
  setBestTimeBand: (value: string) => void;

  odontogramaChangeWire: IOdont[];
  setOdontogramaChangeWire: (value: (prev: IOdont[]) => IOdont[]) => void;

  otherProceduresValueProcedures: any;
  setOtherProceduresValueProcedures: (value: any) => void;

  valueReferralsProcedures: any;
  setValueReferralsProcedures: (value: any) => void;

  receiveHigh: boolean;
  setReceiveHigh: (value: boolean) => void;

  allObservationsProcedure: string;
  setAllObservationsProcedure: (value: string) => void;
  allObservationsPlanning: string;
  setAllObservationsPlanning: (value: string) => void;

  otherProceduresValueSecondConsult: any;
  setOtherProceduresValueSecondConsult: (value: any) => void;

  attachment: boolean;
  setAttachment: (value: boolean) => void;
  changeAttachment: string[];
  setChangeAttachment: (value: string[]) => void;

  odontogramaAttachment: IOdont[];
  setOdontogramaAttachment: (value: (prev: IOdont[]) => IOdont[]) => void;

  realizeIPR: "NO" | "NOT" | "YES";
  setRealizeIPR: (value: "NO" | "NOT" | "YES") => void;

  odontogramaIPR: IOdont[];
  setOdontogramaIPR: (value: (prev: IOdont[]) => IOdont[]) => void;

  orientInvisible: "NO" | "NOT" | "YES";
  setOrientInvisible: (value: "NO" | "NOT" | "YES") => void;

  motiveNoOrient: string;
  setMotiveNoOrient: (value: string) => void;

  orientPeriod: "NO" | "NOT" | "YES";
  setOrientPeriod: (value: "NO" | "NOT" | "YES") => void;

  motivePeriod: string;
  setMotivePeriod: (value: string) => void;

  daysPeriod: 0 | 7 | 10 | 15;
  setDaysPeriod: (value: 0 | 7 | 10 | 15) => void;

  plates: string;
  setPlates: (value: string) => void;

  elasticRequired: "NO" | "NOT" | "YES";
  setElasticRequired: (value: "NO" | "NOT" | "YES") => void;

  attachmentExchange: boolean;
  setAttachmentExchange: (value: boolean) => void;
  changeAttachmentExchange: string[];
  setChangeAttachmentExchange: (value: string[]) => void;

  odontogramaAttachmentExchange: IOdont[];
  setOdontogramaAttachmentExchange: (
    value: (prev: IOdont[]) => IOdont[]
  ) => void;

  realizeIPRExchange: "NO" | "NOT" | "YES";
  setRealizeIPRExchange: (value: "NO" | "NOT" | "YES") => void;

  bracketsNumber: string;
  setBracketsNumber: (value: string) => void;

  keepObservation: string;
  setKeepObservation: (value: string) => void;

  keepDescription: string;
  setKeepDescription: (value: string) => void;
}

const MaintenanceContext = React.createContext({} as IMaintenanceContext);

const MaintenanceProvider: React.FC<IProps> = ({ children }) => {
  const { user, materials, procedures, referrals } = useAuth();

  const [keepDescription, setKeepDescription] = React.useState<string>("");

  const [keepObservation, setKeepObservation] = React.useState<string>("");

  const [bracketsNumber, setBracketsNumber] = React.useState<string>("");

  const [elasticRequired, setElasticRequired] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");

  const [plates, setPlates] = React.useState<string>("");

  const [daysPeriod, setDaysPeriod] = React.useState<0 | 7 | 10 | 15>(0);

  const [odontogramaAttachment, setOdontogramaAttachment] = React.useState<
    IOdont[]
  >([]);

  const [attachmentExchange, setAttachmentExchange] =
    React.useState<boolean>(false);
  const [changeAttachmentExchange, setChangeAttachmentExchange] =
    React.useState<string[]>([]);
  const [odontogramaAttachmentExchange, setOdontogramaAttachmentExchange] =
    React.useState<IOdont[]>([]);
  const [realizeIPRExchange, setRealizeIPRExchange] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");

  const [odontogramaIPR, setOdontogramaIPR] = React.useState<IOdont[]>([]);
  const [attachment, setAttachment] = React.useState<boolean>(false);
  const [changeAttachment, setChangeAttachment] = React.useState<string[]>([]);

  const [realizeIPR, setRealizeIPR] = React.useState<"NOT" | "NO" | "YES">(
    "NOT"
  );

  const [orientInvisible, setOrientInvisible] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");

  const [motiveNoOrient, setMotiveNoOrient] = React.useState<string>("");

  const [orientPeriod, setOrientPeriod] = React.useState<"NOT" | "NO" | "YES">(
    "NOT"
  );

  const [motivePeriod, setMotivePeriod] = React.useState<string>("");

  const [allObservationsProcedure, setAllObservationsProcedure] =
    React.useState<string>("");
  const [allObservationsPlanning, setAllObservationsPlanning] =
    React.useState<string>("");

  const [step, setStep] = React.useState<number>(1);

  const [receiveHigh, setReceiveHigh] = React.useState<boolean>(false);

  const [odontogramaChangeWire, setOdontogramaChangeWire] = React.useState<
    IOdont[]
  >([]);

  const [
    otherProceduresValueSecondConsult,
    setOtherProceduresValueSecondConsult,
  ] = React.useState<any>([]);

  const [valueReferralsProcedures, setValueReferralsProcedures] =
    React.useState<any>([]);

  const [otherProceduresValueProcedures, setOtherProceduresValueProcedures] =
    React.useState<any>([]);

  const [hoursTime, setHoursTime] = React.useState<string[]>([]);

  const [showOtherProcedureScreen, setShowOtherProcedureScreen] =
    React.useState(false);

  const [odontogramaRepOrRec, setOdontogramaRepOrRec] = React.useState<
    IOdont[]
  >([]);

  const [wireProcedures, setWireProcedures] = React.useState<boolean>(false);
  const [changeWireProcedures, setChangeWireProcedures] = React.useState<
    string[]
  >([]);
  const [materialChangeProcedures, setMaterialChangeProcedures] =
    React.useState<string>("");
  const [numberChangeProcedures, setNumberChangeProcedures] =
    React.useState<string>("");
  const [othersChangeProcedures, setOthersChangeProcedures] =
    React.useState<string>("");

  const [materialChangeProceduresInf, setMaterialChangeProceduresInf] =
    React.useState<string>("");
  const [numberChangeProceduresInf, setNumberChangeProceduresInf] =
    React.useState<string>("");
  const [othersChangeProceduresInf, setOthersChangeProceduresInf] =
    React.useState<string>("");

  const [keepThreadProcedures, setKeepThreadProcedures] =
    React.useState<boolean>(false);
  const [keepProcedures, setKeepProcedures] = React.useState<string[]>([]);
  const [ativationProcedures, setAtivationProcedures] =
    React.useState<string>("");

  //MÓVEL

  const [odontogramaSeparator, setOdontogramaSeparator] = React.useState<
    IOdont[]
  >([]);

  const [braceMovel, setBraceMovel] = React.useState<boolean>(false);
  const [relizeMoldage, setRealizeMoldage] = React.useState<boolean>(false);

  //MÓVEL

  // MANUTENÇÂO ABAIXO
  const [braceTypeMaintenance, setBraceTypeMaintenance] = React.useState<
    "NOT" | "UPPER" | "LOWER"
  >("NOT");

  const [repOrRecProcedure, setRepOrRecProcedure] = React.useState<
    "NOT" | "REP" | "REC"
  >("NOT");
  const [repOrRecBraceProcedure, setRepOrRecBraceProcedure] = React.useState<
    "NOT" | "SUP" | "INF"
  >("NOT");

  const [repProcedure, setRepProcedure] = React.useState<boolean>(false);

  const [
    painPrescriptionNeededMaintenance,
    setPainPrescriptionNeededMaintenance,
  ] = React.useState<"NOT" | "NO" | "YES">("NOT");

  const [informedBasicCareMaintenance, setInformedBasicCareMaintenance] =
    React.useState<"NOT" | "NO" | "YES">("NOT");

  const [materialProcedureMaintenance, setMaterialProcedureMaintenance] =
    React.useState("");
  const [numberProcedureMaintenance, setNumberProcedureMaintenance] =
    React.useState("");
  const [
    othersProceduresFixedMaintenance,
    setOthersProceduresFixedMaintenance,
  ] = React.useState("");

  const [
    basicCareObservationsMaintenance,
    setBasicCareObservationsMaintenance,
  ] = React.useState<string>("");

  const [braceTypeMaintenanceReturn, setBraceTypeMaintenanceReturn] =
    React.useState<"NOT" | "UPPER" | "LOWER">("NOT");

  const [repOrRecProcedureReturn, setRepOrRecProcedureReturn] = React.useState<
    "NOT" | "REP" | "REC"
  >("NOT");
  const [repOrRecBraceProcedureReturn, setRepOrRecBraceProcedureReturn] =
    React.useState<"NOT" | "SUP" | "INF">("NOT");

  const [repProcedureReturn, setRepProcedureReturn] =
    React.useState<boolean>(false);

  const [
    painPrescriptionNeededMaintenanceReturn,
    setPainPrescriptionNeededMaintenanceReturn,
  ] = React.useState<"NOT" | "NO" | "YES">("NOT");

  const [
    informedBasicCareMaintenanceReturn,
    setInformedBasicCareMaintenanceReturn,
  ] = React.useState<"NOT" | "NO" | "YES">("NOT");

  const [
    materialProcedureMaintenanceReturn,
    setMaterialProcedureMaintenanceReturn,
  ] = React.useState("");
  const [
    numberProcedureMaintenanceReturn,
    setNumberProcedureMaintenanceReturn,
  ] = React.useState("");
  const [
    othersProceduresFixedMaintenanceReturn,
    setOthersProceduresFixedMaintenanceReturn,
  ] = React.useState("");

  const [
    basicCareObservationsMaintenanceReturn,
    setBasicCareObservationsMaintenanceReturn,
  ] = React.useState<string>("");

  //MANUTENÇÃO ACIMA

  const [showQuestionsReturn, setShowQuestionsReturn] =
    React.useState<boolean>(false);

  const [proceduresLastAttendance, setProceduresLastAttendance] =
    React.useState<string[]>([]);

  const [currentProcedureIndexReturn, setCurrentProcedureIndexReturn] =
    React.useState(0);

  const [odontogramaComplementReturn, setOdontogramaComplementReturn] =
    React.useState<IOdont[]>([]);

  const [odontogramaCollageReturn, setOdontogramaCollageReturn] =
    React.useState<IOdont[]>([]);

  const [odontogramaCimentReturn, setOdontogramaCimentReturn] = React.useState<
    IOdont[]
  >([]);

  const [odontogramaPlanningAparat, setOdontogramaPlanningAparat] =
    React.useState<IOdont[]>([]);

  const [odontogramaPlanningAparatReturn, setOdontogramaPlanningAparatReturn] =
    React.useState<IOdont[]>([]);

  // States Return

  const [justificationReturn, setJustificationReturn] =
    React.useState<string>("");
  const [informateRegionReturn, setInformateRegionReturn] =
    React.useState<string>("");

  const [realizeReturn, setRealizeReturn] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");
  const [realizeContentReturn, setRealizeContentReturn] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeAparatReturn, setRealizeAparatReturn] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeEruptionReturn, setRealizeEruptionReturn] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeIntermaxilaryReturn, setRealizeIntermaxilaryReturn] =
    React.useState<"NO" | "NOT" | "YES">("NOT");

  const [realizeCollageReturn, setRealizeCollageReturn] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeRebondingReturn, setRealizeRebondingReturn] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeBiteReturn, setRealizeBiteReturn] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [routeReturn, setRouteReturn] = React.useState<"NO" | "NOT" | "YES">(
    "NOT"
  );

  const [justifyRouteReturn, setJustifyRouteReturn] =
    React.useState<string>("");

  const [realizeReleaseReturn, setRealizeReleaseReturn] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [justificationContentReturn, setJustificationContentReturn] =
    React.useState<string>("");
  const [informateRegionContentReturn, setInformateRegionContentReturn] =
    React.useState<string>("");

  const [justificationAparatReturn, setJustificationAparatReturn] =
    React.useState<string>("");

  const [justificationEruptionReturn, setJustificationEruptionReturn] =
    React.useState<string>("");
  const [informateRegionEruptionReturn, setInformateRegionEruptionReturn] =
    React.useState<string>("");

  const [
    justificationIntermaxilaryReturn,
    setJustificationIntermaxilaryReturn,
  ] = React.useState<string>("");

  const [justificationCollageReturn, setJustificationCollageReturn] =
    React.useState<string>("");

  const [justificationRebondingReturn, setJustificationRebondingReturn] =
    React.useState<string>("");

  const [justificationBiteReturn, setJustificationBiteReturn] =
    React.useState<string>("");

  const [justificationReleaseReturn, setJustificationReleaseReturn] =
    React.useState<string>("");

  // End States Return

  const [justify01, setJustify01] = React.useState<string>("");
  const [justify02, setJustify02] = React.useState<string>("");
  const [justify03, setJustify03] = React.useState<string>("");
  const [justify04, setJustify04] = React.useState<string>("");
  const [justify05, setJustify05] = React.useState<string>("");
  const [justify06, setJustify06] = React.useState<string>("");
  const [justify07, setJustify07] = React.useState<string>("");
  const [justify08, setJustify08] = React.useState<string>("");
  const [justify09, setJustify09] = React.useState<string>("");

  const [currentProcedureIndex, setCurrentProcedureIndex] = React.useState(0);

  const [justification, setJustification] = React.useState<string>("");
  const [informateRegion, setInformateRegion] = React.useState<string>("");

  const [realize, setRealize] = React.useState<"NO" | "NOT" | "YES">("NOT");
  const [realizeContent, setRealizeContent] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeAparat, setRealizeAparat] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeEruption, setRealizeEruption] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeIntermaxilary, setRealizeIntermaxilary] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeCollage, setRealizeCollage] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeRebonding, setRealizeRebonding] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [realizeBite, setRealizeBite] = React.useState<"NO" | "NOT" | "YES">(
    "NOT"
  );

  const [route, setRoute] = React.useState<"NO" | "NOT" | "YES">("NOT");

  const [justifyRoute, setJustifyRoute] = React.useState<string>("");

  const [realizeRelease, setRealizeRelease] = React.useState<
    "NO" | "NOT" | "YES"
  >("NOT");

  const [justificationContent, setJustificationContent] =
    React.useState<string>("");
  const [informateRegionContent, setInformateRegionContent] =
    React.useState<string>("");

  const [justificationAparat, setJustificationAparat] =
    React.useState<string>("");

  const [justificationEruption, setJustificationEruption] =
    React.useState<string>("");
  const [informateRegionEruption, setInformateRegionEruption] =
    React.useState<string>("");

  const [justificationIntermaxilary, setJustificationIntermaxilary] =
    React.useState<string>("");

  const [justificationCollage, setJustificationCollage] =
    React.useState<string>("");

  const [justificationRebonding, setJustificationRebonding] =
    React.useState<string>("");

  const [justificationBite, setJustificationBite] = React.useState<string>("");

  const [justificationRelease, setJustificationRelease] =
    React.useState<string>("");

  const [onlyBand, setOnlyBand] = React.useState<boolean>(false);

  const [separatorId, setSeparatorId] = React.useState<number>(0);

  const [mostImportantType, setMostImportantType] = React.useState<string>("");
  const [bandaExists, setBandaExists] = React.useState<boolean>(false);
  const [bandaId, setBandaId] = React.useState<number>(0);

  const [selectedProceduresId, setSelectedProceduresId] =
    React.useState<number>(0);

  const [bestTime, setBestTime] = React.useState<string>("");

  const [bestTimeBand, setBestTimeBand] = React.useState<string>("");

  const [videoClicked, setVideoClicked] = React.useState<boolean>(false);

  const [odontogramaComplement, setOdontogramaComplement] = React.useState<
    IOdont[]
  >([]);

  const [odontogramaCollage, setOdontogramaCollage] = React.useState<IOdont[]>(
    []
  );

  const [odontogramaCiment, setOdontogramaCiment] = React.useState<IOdont[]>(
    []
  );

  const [odontogramaAparat, setOdontogramaAparat] = React.useState<IOdont[]>(
    []
  );

  const [questionsPlanning, setQuestionsPlanning] = React.useState<
    NextAppointment[]
  >([]);

  const [aparateReturn, setAparateReturn] = React.useState<boolean>(false);
  const [aparateBraceReturn, setAparateBraceReturn] = React.useState<string[]>(
    []
  );
  const [materialReturn, setMaterialReturn] = React.useState<string>("");
  const [numberReturn, setNumberReturn] = React.useState<string>("");
  const [othersReturn, setOthersReturn] = React.useState<string>("");

  const [materialReturnInf, setMaterialReturnInf] = React.useState<string>("");
  const [numberReturnInf, setNumberReturnInf] = React.useState<string>("");
  const [othersReturnInf, setOthersReturnInf] = React.useState<string>("");

  const [wireReturn, setWireReturn] = React.useState<boolean>(false);
  const [changeWireReturn, setChangeWireReturn] = React.useState<string[]>([]);
  const [materialChangeReturn, setMaterialChangeReturn] =
    React.useState<string>("");
  const [numberChangeReturn, setNumberChangeReturn] =
    React.useState<string>("");
  const [othersChangeReturn, setOthersChangeReturn] =
    React.useState<string>("");

  const [materialChangeReturnInf, setMaterialChangeReturnInf] =
    React.useState<string>("");
  const [numberChangeReturnInf, setNumberChangeReturnInf] =
    React.useState<string>("");
  const [othersChangeReturnInf, setOthersChangeReturnInf] =
    React.useState<string>("");

  const [keepThreadReturn, setKeepThreadReturn] =
    React.useState<boolean>(false);
  const [keepReturn, setKeepReturn] = React.useState<string[]>([]);
  const [ativationReturn, setAtivationReturn] = React.useState<string>("");

  const [cimentReturn, setCimentReturn] = React.useState<boolean>(false);
  const [cimentBandReturn, setCimentBandReturn] = React.useState<string[]>([]);

  const [glueReturn, setGlueReturn] = React.useState<boolean>(false);
  const [glueTubReturn, setGlueTubReturn] = React.useState<string[]>([]);

  const [repReturn, setRepReturn] = React.useState<boolean>(false);
  const [repOrRecReturn, setRepOrRecReturn] = React.useState<
    "NOT" | "REP" | "REC"
  >("NOT");
  const [repOrRecBraceReturn, setRepOrRecBraceReturn] = React.useState<
    "NOT" | "SUP" | "INF"
  >("NOT");

  const [complementReturn, setComplementReturn] =
    React.useState<boolean>(false);
  const [compReturn, setCompReturn] = React.useState<any[]>([]);

  const [othersProceduresReturn, setOthersProceduresReturn] =
    React.useState<boolean>(false);
  const [valueOthersReturn, setValueOthersReturn] = React.useState<any>([]);

  const [othersReferralsReturn, setOthersReferralsReturn] =
    React.useState<boolean>(false);
  const [valueEncReturn, setValueEncReturn] = React.useState<any>([]);

  /////////////////////////////////////////////////////////////

  const [painPrescriptionObservations, setPainPrescriptionObservations] =
    React.useState<string>("");
  const [basicCareObservations, setBasicCareObservations] =
    React.useState<string>("");
  const [treatmentManualObservations, setTreatmentManualObservations] =
    React.useState<string>("");
  const [wireAndWaxObservations, setWireAndWaxObservations] =
    React.useState<string>("");

  const [errorPainPrescription, setErrorPainPrescription] =
    React.useState<boolean>(false);
  const [errorBasicCare, setErrorBasicCare] = React.useState<boolean>(false);
  const [errorTreatmentManual, setErrorTreatmentManual] =
    React.useState<boolean>(false);
  const [errorWireAndWax, setErrorWireAndWax] = React.useState<boolean>(false);

  /*Odontograma*/

  const [odontogramaRemote, setOdontogramaRemote] = React.useState<IOdont[]>(
    []
  );
  const [odontogramaInstall, setOdontogramaInstall] = React.useState<IOdont[]>(
    []
  );
  const [odontogramaButton, setOdontogramaButton] = React.useState<IOdont[]>(
    []
  );
  const [odontogramaIntermax, setOdontogramaIntermax] = React.useState<
    IOdont[]
  >([]);
  const [odontogramaLevant, setOdontogramaLevant] = React.useState<IOdont[]>(
    []
  );

  const [odontogramaRemoteReturn, setOdontogramaRemoteReturn] = React.useState<
    IOdont[]
  >([]);
  const [odontogramaInstallReturn, setOdontogramaInstallReturn] =
    React.useState<IOdont[]>([]);
  const [odontogramaButtonReturn, setOdontogramaButtonReturn] = React.useState<
    IOdont[]
  >([]);
  const [odontogramaIntermaxReturn, setOdontogramaIntermaxReturn] =
    React.useState<IOdont[]>([]);
  const [odontogramaLevantReturn, setOdontogramaLevantReturn] = React.useState<
    IOdont[]
  >([]);

  /*End Odontograma*/

  //Init States Anamnese
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [answers, setAnswers] = React.useState<{ [key: string]: string }>({});
  const [observations, setObservations] = React.useState<{
    [key: string]: string;
  }>({});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [selectedOptions, setSelectedOptions] = React.useState<IAnamnese[]>([]);

  //End States Anamnese

  //Init States Diagnosis
  const [diagnosisDescription, setDiagnosisDescription] =
    React.useState<string>("");
  const [stepDiagnosis, setStepDiagnosis] = React.useState<number>(1);
  const [btnCirurgy, setBtnCirurgy] = React.useState<"YES" | "NO" | "NOT">(
    "NOT"
  );

  const [btnOrtognatica, setBtnOrtognatica] = React.useState<
    "YES" | "NO" | "NOT"
  >("NOT");

  const [btnOthers, setBtnOthers] = React.useState<"YES" | "NO" | "NOT">("NOT");
  const [btnPatients, setBtnPatients] = React.useState<"YES" | "NO" | "NOT">(
    "NOT"
  );
  const [braceComplement, setBraceComplement] = React.useState<boolean>(false);
  const [brace, setBrace] = React.useState<0 | 1 | 2>(0);
  const [checkedState, setCheckedState] = React.useState<{
    [key: string]: boolean;
  }>(() =>
    ComplementData.reduce((acc, _, index) => {
      acc[index] = false;
      return acc;
    }, {} as { [key: string]: boolean })
  );

  //End States Diagnosis

  //Init States Schedule
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(
    null
  );
  const [selectedTime, setSelectedTime] = React.useState<string>("");
  const [selectedHour, setSelectedHour] = React.useState<string>("");

  const [selectedDateBand, setSelectedDateBand] =
    React.useState<dayjs.Dayjs | null>(null);
  const [selectedTimeBand, setSelectedTimeBand] = React.useState<string>("");
  const [selectedHourBand, setSelectedHourBand] = React.useState<string>("");

  //End States Schedule

  //Init States Planning

  const [othersProcedures, setOthersProcedures] =
    React.useState<boolean>(false);

  const [othersReferrals, setOthersReferrals] = React.useState<boolean>(false);

  const [regionDescription, setRegionDescription] = React.useState<string>("");

  const [valueOthers, setValueOthers] = React.useState<any>([]);

  const [valueEnc, setValueEnc] = React.useState<any>([]);

  const [aparate, setAparate] = React.useState<boolean>(false);
  const [wire, setWire] = React.useState<boolean>(false);
  const [keepThread, setKeepThread] = React.useState<boolean>(false);
  const [ciment, setCiment] = React.useState<boolean>(false);
  const [glue, setGlue] = React.useState<boolean>(false);
  const [complement, setComplement] = React.useState<boolean>(false);
  const [rep, setRep] = React.useState<boolean>(false);

  const [aparateBrace, setAparateBrace] = React.useState<string[]>([]);

  const [changeWire, setChangeWire] = React.useState<string[]>([]);

  const [keep, setKeep] = React.useState<string[]>([]);
  const [cimentBand, setCimentBand] = React.useState<string[]>([]);
  const [glueTube, setGlueTube] = React.useState<string[]>([]);
  const [comp, setComp] = React.useState<ILabelValue[]>([]);
  const [repOrRec, setRepOrRec] = React.useState<string[]>([]);
  const [repOrRecBrace, setRepOrRecBrace] = React.useState<string[]>([]);

  const [materialCola, setMaterialCola] = React.useState("");
  const [numberCola, setNumberCola] = React.useState("");
  const [othersCola, setOthersCola] = React.useState("");

  const [materialColaInf, setMaterialColaInf] = React.useState("");
  const [numberColaInf, setNumberColaInf] = React.useState("");
  const [othersColaInf, setOthersColaInf] = React.useState("");

  const [materialChange, setMaterialChange] = React.useState("");
  const [numberChange, setNumberChange] = React.useState("");
  const [othersChange, setOthersChange] = React.useState("");

  const [materialChangeInf, setMaterialChangeInf] = React.useState("");
  const [numberChangeInf, setNumberChangeInf] = React.useState("");
  const [othersChangeInf, setOthersChangeInf] = React.useState("");

  const [ativation, setAtivation] = React.useState<string>("");

  //End States Planning

  //Init States Procedures

  const [materialProcedure, setMaterialProcedure] = React.useState("");
  const [numberProcedure, setNumberProcedure] = React.useState("");
  const [othersProceduresFixed, setOthersProceduresFixed] = React.useState("");
  const [needsBand, setNeedsBand] = React.useState<"NOT" | "NO" | "YES">("NOT");
  const [needsSeparator, setNeedsSeparator] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");
  const [performMolding, setPerformMolding] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");
  const [installSeparator, setInstallSeparator] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");
  const [moldingJustification, setMoldingJustification] = React.useState("");
  const [separatorJustification, setSeparatorJustification] =
    React.useState("");

  const [braceType, setBraceType] = React.useState<"NOT" | "UPPER" | "LOWER">(
    "NOT"
  );
  const [painPrescriptionNeeded, setPainPrescriptionNeeded] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");
  const [informedBasicCare, setInformedBasicCare] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");
  const [deliveredTreatmentManual, setDeliveredTreatmentManual] =
    React.useState<"NOT" | "NO" | "YES">("NOT");
  const [advisedWireAndWax, setAdvisedWireAndWax] = React.useState<
    "NOT" | "NO" | "YES"
  >("NOT");
  const [additionalDevice, setAdditionalDevice] = React.useState<any>();
  const [hasComplement, setHasComplement] = React.useState<boolean>(false);
  const [hasOtherProcedures, setHasOtherProcedures] =
    React.useState<boolean>(false);
  const [hasOtherReferrals, setHasOtherReferrals] =
    React.useState<boolean>(false);
  const [otherProceduresValue, setOtherProceduresValue] = React.useState<any>(
    []
  );
  const [referralValue, setReferralValue] = React.useState<any>([]);
  const [regionDescriptionProcedure, setRegionDescriptionProcedure] =
    React.useState<string>("");

  const [showPainPrescriptionNeeded, setShowPainPrescriptionNeeded] =
    React.useState(false);
  const [showInformedBasicCare, setShowInformedBasicCare] =
    React.useState(false);
  const [showDeliveredTreatmentManual, setShowDeliveredTreatmentManual] =
    React.useState(false);
  const [showAdvisedWireAndWax, setShowAdvisedWireAndWax] =
    React.useState(false);
  const [showAdditionalDevice, setShowAdditionalDevice] = React.useState(false);

  //End States Procedures

  //Init Functions Anamnese
  const handleOptionChange = React.useCallback(
    (option: IAnamnese) => {
      const newSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((o) => o !== option)
        : [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);
    },
    [selectedOptions]
  );

  const handleAnswerChange = React.useCallback(
    (questionText: string, answer: string) => {
      const currentIndex = questions.findIndex(
        (q) => q.questionText === questionText
      );

      // Limpar respostas e observações das perguntas subsequentes
      for (let i = currentIndex + 1; i < questions.length; i++) {
        delete answers[questions[i].questionText];
        delete observations[questions[i].questionText];
        delete errors[questions[i].questionText];
      }
      setAnswers({ ...answers, [questionText]: answer });

      // Validar observação se "sim" for selecionado
      if (answer === "sim" && !observations[questionText]) {
        setErrors({ ...errors, [questionText]: "Observação é obrigatória" });
      } else {
        const newErrors = { ...errors };
        delete newErrors[questionText];
        setErrors(newErrors);
      }

      setCurrentQuestionIndex(currentIndex + 1);
    },
    [answers, errors, observations]
  );

  const handleObservationChange = React.useCallback(
    (questionText: string, observation: string) => {
      setObservations({ ...observations, [questionText]: observation });
      if (answers[questionText] === "sim" && observation.trim() === "") {
        setErrors({ ...errors, [questionText]: "Observação é obrigatória" });
      } else {
        const newErrors = { ...errors };
        delete newErrors[questionText];
        setErrors(newErrors);
      }
    },
    [setObservations, setErrors, answers, observations, errors]
  );

  //End Functions Anamnese

  //Init Function Diagnosis
  const handleCheckboxChange = React.useCallback((index: number) => {
    setCheckedState((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }, []);
  //End Function Diagnosis

  //Init Function Schedule

  const handleDateChange = React.useCallback((date: dayjs.Dayjs | null) => {
    setSelectedDate(date);
    setSelectedHour("");
  }, []);

  const handleTimeChange = React.useCallback((event: SelectChangeEvent) => {
    setSelectedTime(event.target.value);
    setSelectedHour("");
  }, []);

  const handleHourChange = React.useCallback((event: SelectChangeEvent) => {
    setSelectedHour(event.target.value);
  }, []);

  const handleDateChangeBand = React.useCallback((date: dayjs.Dayjs | null) => {
    setSelectedDateBand(date);
    setSelectedHourBand("");
  }, []);

  const handleTimeChangeBand = React.useCallback((event: SelectChangeEvent) => {
    setSelectedTimeBand(event.target.value);
    setSelectedHourBand("");
  }, []);

  const handleHourChangeBand = React.useCallback((event: SelectChangeEvent) => {
    setSelectedHourBand(event.target.value);
  }, []);

  //End Function Schedule

  const patientActually = localStorage.getItem("personAttendance");

  const patientObj = JSON.parse(patientActually as string);

  const callData = React.useCallback(async () => {
    const { status, data } = await getClinicalRecords({
      idPac: patientObj.pac_id,
      userId: user.data.id,
    });

    if ([200, 201].includes(status)) {
      if (data.data.length > 0) {
        //const dataLast = data.data[data.data.length - 1];
        const dataLast = data.data[0];

        setQuestionsPlanning(dataLast.nextAppointment);

        const collageAparateSup = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ001" && item.position === 1
        );

        const collageAparateInf = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ001" && item.position === 0
        );

        const collageAparateOdont = dataLast.odont.filter(
          (item) =>
            item.procedure ===
            "Colagem de aparatologia ortodôntica - Planejamento"
        );

        const changeWireSup = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ002" && item.position === 1
        );

        const changeWireInf = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ002" && item.position === 0
        );

        const keepThread = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ003"
        );

        const cimentBand = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ004"
        );

        const cimentBandOdont = dataLast.odont.filter(
          (item) => item.procedure === "Cimentação de banda"
        );

        const collageTub = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ005"
        );

        const collageTubOdont = dataLast.odont.filter(
          (item) => item.procedure === "Colagem do tubo"
        );

        const complementWire = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ007"
        );

        const complementWireOdont = dataLast.odont.filter(
          (item) => item.procedure === "Aparelho Complementar"
        );

        const procedureOthers = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ008"
        );

        const encami = dataLast.nextAppointment.filter(
          (item) => item.id === "PPQ009"
        );

        if (collageAparateOdont.length > 0) {
          setOdontogramaPlanningAparatReturn(collageAparateOdont);
        }

        if (collageAparateSup.length > 0 && collageAparateInf.length < 1) {
          setAparateReturn(true);

          setAparateBraceReturn(["SUP"]);

          if (collageAparateSup && collageAparateSup[0]) {
            const metaData = collageAparateSup[0].metaData;

            // Verifica se metaData é um objeto
            if (typeof metaData === "object" && !Array.isArray(metaData)) {
              const type = Number(metaData.type);
              const wire = Number(metaData.wire);

              const returnNumber =
                type === 1
                  ? "12 Redondo"
                  : type === 2
                  ? "14 Redondo"
                  : type === 3
                  ? "16 Redondo"
                  : type === 4
                  ? "18 Redondo"
                  : type === 5
                  ? "20 Redondo"
                  : type === 6
                  ? "16x22 Retangular"
                  : type === 7
                  ? "17x25 Retangular"
                  : type === 8
                  ? "18x25 Retangular"
                  : type === 9
                  ? "19x25 Retangular"
                  : "Tipo Desconhecido";
              const returnWire =
                wire === 1
                  ? "Niti"
                  : wire === 2
                  ? "Aço"
                  : wire === 3
                  ? "Niti Curva Reversa"
                  : wire === 4
                  ? "Aço Curva Reversa"
                  : "";
              setMaterialReturn(returnWire);
              setNumberReturn(returnNumber);
            }
          }
        }

        if (collageAparateSup.length < 1 && collageAparateInf.length > 0) {
          setAparateReturn(true);

          setAparateBraceReturn(["INF"]);

          const metaData = collageAparateInf[0].metaData;

          // Verifica se metaData é um objeto
          if (typeof metaData === "object" && !Array.isArray(metaData)) {
            const type = Number(metaData.type);
            const wire = Number(metaData.wire);

            const returnNumber =
              type === 1
                ? "12 Redondo"
                : type === 2
                ? "14 Redondo"
                : type === 3
                ? "16 Redondo"
                : type === 4
                ? "18 Redondo"
                : type === 5
                ? "20 Redondo"
                : type === 6
                ? "16x22 Retangular"
                : type === 7
                ? "17x25 Retangular"
                : type === 8
                ? "18x25 Retangular"
                : type === 9
                ? "19x25 Retangular"
                : "Tipo Desconhecido";
            const returnWire =
              wire === 1
                ? "Niti"
                : wire === 2
                ? "Aço"
                : wire === 3
                ? "Niti Curva Reversa"
                : wire === 4
                ? "Aço Curva Reversa"
                : "";
            setMaterialReturnInf(returnWire);
            setNumberReturnInf(returnNumber);
          }
        }

        if (collageAparateSup.length > 0 && collageAparateInf.length > 0) {
          setAparateReturn(true);

          setAparateBraceReturn(["INF", "SUP"]);

          if (collageAparateInf && collageAparateInf[0]) {
            const metaData = collageAparateInf[0].metaData;

            // Verifica se metaData é um objeto
            if (typeof metaData === "object" && !Array.isArray(metaData)) {
              const type = Number(metaData.type);
              const wire = Number(metaData.wire);

              const returnNumber =
                type === 1
                  ? "12 Redondo"
                  : type === 2
                  ? "14 Redondo"
                  : type === 3
                  ? "16 Redondo"
                  : type === 4
                  ? "18 Redondo"
                  : type === 5
                  ? "20 Redondo"
                  : type === 6
                  ? "16x22 Retangular"
                  : type === 7
                  ? "17x25 Retangular"
                  : type === 8
                  ? "18x25 Retangular"
                  : type === 9
                  ? "19x25 Retangular"
                  : "Tipo Desconhecido";
              const returnWire =
                wire === 1
                  ? "Niti"
                  : wire === 2
                  ? "Aço"
                  : wire === 3
                  ? "Niti Curva Reversa"
                  : wire === 4
                  ? "Aço Curva Reversa"
                  : "";
              setMaterialReturnInf(returnWire);
              setNumberReturnInf(returnNumber);
            }
          }

          if (collageAparateSup && collageAparateSup[0]) {
            const metaData = collageAparateSup[0].metaData;

            // Verifica se metaData é um objeto
            if (typeof metaData === "object" && !Array.isArray(metaData)) {
              const type = Number(metaData.type);
              const wire = Number(metaData.wire);

              const returnNumber =
                type === 1
                  ? "12 Redondo"
                  : type === 2
                  ? "14 Redondo"
                  : type === 3
                  ? "16 Redondo"
                  : type === 4
                  ? "18 Redondo"
                  : type === 5
                  ? "20 Redondo"
                  : type === 6
                  ? "16x22 Retangular"
                  : type === 7
                  ? "17x25 Retangular"
                  : type === 8
                  ? "18x25 Retangular"
                  : type === 9
                  ? "19x25 Retangular"
                  : "Tipo Desconhecido";
              const returnWire =
                wire === 1
                  ? "Niti"
                  : wire === 2
                  ? "Aço"
                  : wire === 3
                  ? "Niti Curva Reversa"
                  : wire === 4
                  ? "Aço Curva Reversa"
                  : "";
              setMaterialReturn(returnWire);
              setNumberReturn(returnNumber);
            }
          }
        }

        if (changeWireSup.length > 0 && changeWireInf.length < 1) {
          setWireReturn(true);

          setChangeWireReturn(["SUP"]);

          if (changeWireSup && changeWireSup[0]) {
            const metaData = changeWireSup[0].metaData;

            // Verifica se metaData é um objeto
            if (typeof metaData === "object" && !Array.isArray(metaData)) {
              const type = Number(metaData.type);
              const wire = Number(metaData.wire);

              // Mapeamento para retornar o número correspondente ao tipo
              const returnNumber =
                type === 1
                  ? "12 Redondo"
                  : type === 2
                  ? "14 Redondo"
                  : type === 3
                  ? "16 Redondo"
                  : type === 4
                  ? "18 Redondo"
                  : type === 5
                  ? "20 Redondo"
                  : type === 6
                  ? "16x22 Retangular"
                  : type === 7
                  ? "17x25 Retangular"
                  : type === 8
                  ? "18x25 Retangular"
                  : type === 9
                  ? "19x25 Retangular"
                  : "Tipo Desconhecido";

              // Mapeamento para retornar o material correspondente ao fio
              const returnWire =
                wire === 1
                  ? "Niti"
                  : wire === 2
                  ? "Aço"
                  : wire === 3
                  ? "Niti Curva Reversa"
                  : wire === 4
                  ? "Aço Curva Reversa"
                  : "";

              setMaterialChangeReturn(returnWire);
              setNumberChangeReturn(returnNumber);
            }
          }
        }

        if (changeWireSup.length < 1 && changeWireInf.length > 0) {
          setWireReturn(true);

          setChangeWireReturn(["INF"]);

          if (changeWireInf && changeWireInf[0]) {
            const metaData = changeWireInf[0].metaData;

            // Verifica se metaData é um objeto
            if (typeof metaData === "object" && !Array.isArray(metaData)) {
              const type = Number(metaData.type);
              const wire = Number(metaData.wire);

              // Mapeamento para retornar o número correspondente ao tipo
              const returnNumber =
                type === 1
                  ? "12 Redondo"
                  : type === 2
                  ? "14 Redondo"
                  : type === 3
                  ? "16 Redondo"
                  : type === 4
                  ? "18 Redondo"
                  : type === 5
                  ? "20 Redondo"
                  : type === 6
                  ? "16x22 Retangular"
                  : type === 7
                  ? "17x25 Retangular"
                  : type === 8
                  ? "18x25 Retangular"
                  : type === 9
                  ? "19x25 Retangular"
                  : "Tipo Desconhecido";

              // Mapeamento para retornar o material correspondente ao fio
              const returnWire =
                wire === 1
                  ? "Niti"
                  : wire === 2
                  ? "Aço"
                  : wire === 3
                  ? "Niti Curva Reversa"
                  : wire === 4
                  ? "Aço Curva Reversa"
                  : "";

              setMaterialChangeReturnInf(returnWire);
              setNumberChangeReturnInf(returnNumber);
            }
          }
        }

        if (changeWireSup.length > 0 && changeWireInf.length > 0) {
          setWireReturn(true);

          setChangeWireReturn(["INF", "SUP"]);

          if (changeWireInf && changeWireInf[0]) {
            const metaData = changeWireInf[0].metaData;

            // Verifica se metaData é um objeto
            if (typeof metaData === "object" && !Array.isArray(metaData)) {
              const type = Number(metaData.type);
              const wire = Number(metaData.wire);

              // Mapeamento para retornar o número correspondente ao tipo
              const returnNumber =
                type === 1
                  ? "12 Redondo"
                  : type === 2
                  ? "14 Redondo"
                  : type === 3
                  ? "16 Redondo"
                  : type === 4
                  ? "18 Redondo"
                  : type === 5
                  ? "20 Redondo"
                  : type === 6
                  ? "16x22 Retangular"
                  : type === 7
                  ? "17x25 Retangular"
                  : type === 8
                  ? "18x25 Retangular"
                  : type === 9
                  ? "19x25 Retangular"
                  : "Tipo Desconhecido";

              // Mapeamento para retornar o material correspondente ao fio
              const returnWire =
                wire === 1
                  ? "Niti"
                  : wire === 2
                  ? "Aço"
                  : wire === 3
                  ? "Niti Curva Reversa"
                  : wire === 4
                  ? "Aço Curva Reversa"
                  : "";

              setMaterialChangeReturnInf(returnWire);
              setNumberChangeReturnInf(returnNumber);
            }
          }

          if (changeWireSup && changeWireSup[0]) {
            const metaData = changeWireSup[0].metaData;

            // Verifica se metaData é um objeto
            if (typeof metaData === "object" && !Array.isArray(metaData)) {
              const type = Number(metaData.type);
              const wire = Number(metaData.wire);

              // Mapeamento para retornar o número correspondente ao tipo
              const returnNumber =
                type === 1
                  ? "12 Redondo"
                  : type === 2
                  ? "14 Redondo"
                  : type === 3
                  ? "16 Redondo"
                  : type === 4
                  ? "18 Redondo"
                  : type === 5
                  ? "20 Redondo"
                  : type === 6
                  ? "16x22 Retangular"
                  : type === 7
                  ? "17x25 Retangular"
                  : type === 8
                  ? "18x25 Retangular"
                  : type === 9
                  ? "19x25 Retangular"
                  : "Tipo Desconhecido";

              // Mapeamento para retornar o material correspondente ao fio
              const returnWire =
                wire === 1
                  ? "Niti"
                  : wire === 2
                  ? "Aço"
                  : wire === 3
                  ? "Niti Curva Reversa"
                  : wire === 4
                  ? "Aço Curva Reversa"
                  : "";

              setMaterialChangeReturn(returnWire);
              setNumberChangeReturn(returnNumber);
            }
          }
        }

        if (keepThread.length > 0) {
          setKeepThreadReturn(true);

          if (keepThread[0].position === 1) {
            setKeepReturn(["SUP"]);
          }

          if (keepThread[0].position === 0) {
            setKeepReturn(["INF"]);
          }

          if (keepThread[0].position === 3) {
            setKeepReturn(["INF", "SUP"]);
          }

          if (keepThread && keepThread[0]) {
            if (keepThread[0].activation === 1) {
              setAtivationReturn("yes");
            } else {
              setAtivationReturn("no");
            }
          }
        }

        if (cimentBand.length > 0) {
          setCimentReturn(true);

          if (cimentBandOdont.length > 0) {
            setOdontogramaCimentReturn(cimentBandOdont);
          }

          if (cimentBand[0].position === 1) {
            setCimentBandReturn(["SUP"]);
          }

          if (cimentBand[0].position === 0) {
            setCimentBandReturn(["INF"]);
          }

          if (cimentBand[0].position === 3) {
            setCimentBandReturn(["INF", "SUP"]);
          }
        }

        if (collageTub.length > 0) {
          setGlueReturn(true);

          if (collageTubOdont.length > 0) {
            setOdontogramaCollageReturn(collageTubOdont);
          }

          if (collageTub[0].position === 1) {
            setGlueTubReturn(["SUP"]);
          }

          if (collageTub[0].position === 0) {
            setGlueTubReturn(["INF"]);
          }

          if (collageTub[0].position === 3) {
            setGlueTubReturn(["INF", "SUP"]);
          }
        }

        if (complementWire.length > 0) {
          setComplementReturn(true);

          if (complementWireOdont.length > 0) {
            setOdontogramaComplementReturn(complementWireOdont);
          }

          const returnObj = complementWire[0].metaData;

          if (Array.isArray(returnObj) && returnObj.length > 0) {
            setCompReturn(returnObj);
          }
        }

        if (procedureOthers.length > 0) {
          setOthersProceduresReturn(true);

          const returnObj = procedureOthers[0].metaData;

          if (Array.isArray(returnObj) && returnObj.length > 0) {
            const onlyLabel = returnObj.map(
              (item: { label: string }) => item.label
            );

            setProceduresLastAttendance(onlyLabel);
            //setShowQuestionsReturn(true);

            setOtherProceduresValue(onlyLabel);
          }
        }

        if (encami.length > 0) {
          setOthersReferralsReturn(true);

          const returnObj = encami[0].metaData;

          if (Array.isArray(returnObj)) {
            // Define o estado com o array de strings
            setValueEncReturn(returnObj);
          }
        }
      }
    }
  }, [patientObj.pac_id, user.data.id]);

  React.useEffect(() => {
    callData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    materials({ userId: user.data.id, idPac: patientObj.pac_id });
    procedures({
      userId: user.data.id,
      type: patientObj.type,
      idPac: patientObj.pac_id,
    });
    referrals({
      id: user.data.id,
      idSchedule: patientObj.id,
      idPac: patientObj.pac_id,
    });
  }, [
    materials,
    patientObj.id,
    patientObj.pac_id,
    patientObj.type,
    procedures,
    referrals,
    user.data.id,
  ]);

  const value = React.useMemo(
    () => ({
      step,
      setStep,
      patientObj,
      selectedOptions,
      handleOptionChange,
      currentQuestionIndex,
      answers,
      handleAnswerChange,
      observations,
      errors,
      handleObservationChange,
      stepDiagnosis,
      setStepDiagnosis,
      btnOrtognatica,
      setBtnOrtognatica,
      btnCirurgy,
      setBtnCirurgy,
      btnOthers,
      setBtnOthers,
      btnPatients,
      setBtnPatients,
      braceComplement,
      setBraceComplement,
      brace,
      setBrace,
      checkedState,
      handleCheckboxChange,
      selectedDate,
      selectedHour,
      selectedTime,
      handleDateChange,
      handleTimeChange,
      handleHourChange,
      othersProcedures,
      setOthersProcedures,
      othersReferrals,
      setOthersReferrals,
      regionDescription,
      setRegionDescription,
      valueOthers,
      setValueOthers,
      valueEnc,
      setValueEnc,
      aparate,
      setAparate,
      wire,
      setWire,
      keepThread,
      setKeepThread,
      ciment,
      setCiment,
      glue,
      setGlue,
      complement,
      setComplement,
      rep,
      setRep,
      aparateBrace,
      setAparateBrace,
      changeWire,
      setChangeWire,
      keep,
      setKeep,
      cimentBand,
      setCimentBand,
      glueTube,
      setGlueTube,
      comp,
      setComp,
      repOrRec,
      setRepOrRec,
      repOrRecBrace,
      setRepOrRecBrace,
      needsBand,
      setNeedsBand,
      needsSeparator,
      setNeedsSeparator,
      performMolding,
      setPerformMolding,
      installSeparator,
      setInstallSeparator,
      moldingJustification,
      setMoldingJustification,
      separatorJustification,
      setSeparatorJustification,
      braceType,
      setBraceType,
      painPrescriptionNeeded,
      setPainPrescriptionNeeded,
      informedBasicCare,
      setInformedBasicCare,
      deliveredTreatmentManual,
      setDeliveredTreatmentManual,
      advisedWireAndWax,
      setAdvisedWireAndWax,
      additionalDevice,
      setAdditionalDevice,
      hasComplement,
      setHasComplement,
      hasOtherProcedures,
      setHasOtherProcedures,
      hasOtherReferrals,
      setHasOtherReferrals,
      otherProceduresValue,
      setOtherProceduresValue,
      referralValue,
      setReferralValue,
      regionDescriptionProcedure,
      setRegionDescriptionProcedure,
      showPainPrescriptionNeeded,
      setShowPainPrescriptionNeeded,
      showInformedBasicCare,
      setShowInformedBasicCare,
      showDeliveredTreatmentManual,
      setShowDeliveredTreatmentManual,
      showAdvisedWireAndWax,
      setShowAdvisedWireAndWax,
      showAdditionalDevice,
      setShowAdditionalDevice,
      diagnosisDescription,
      setDiagnosisDescription,
      materialProcedure,
      setMaterialProcedure,
      numberProcedure,
      setNumberProcedure,
      othersProceduresFixed,
      setOthersProceduresFixed,
      materialCola,
      setMaterialCola,
      numberCola,
      setNumberCola,
      othersCola,
      setOthersCola,
      materialChange,
      setMaterialChange,
      numberChange,
      setNumberChange,
      othersChange,
      setOthersChange,
      ativation,
      setAtivation,
      odontogramaComplement,
      setOdontogramaComplement,
      odontogramaCollage,
      setOdontogramaCollage,
      odontogramaCiment,
      setOdontogramaCiment,
      bestTime,
      setBestTime,
      setSelectedHour,
      videoClicked,
      setVideoClicked,
      painPrescriptionObservations,
      setPainPrescriptionObservations,
      basicCareObservations,
      setBasicCareObservations,
      treatmentManualObservations,
      setTreatmentManualObservations,
      wireAndWaxObservations,
      setWireAndWaxObservations,
      errorPainPrescription,
      setErrorPainPrescription,
      errorBasicCare,
      setErrorBasicCare,
      errorTreatmentManual,
      setErrorTreatmentManual,
      errorWireAndWax,
      setErrorWireAndWax,
      odontogramaAparat,
      setOdontogramaAparat,
      questionsPlanning,
      aparateReturn,
      setAparateReturn,
      aparateBraceReturn,
      setAparateBraceReturn,
      materialReturn,
      setMaterialReturn,
      numberReturn,
      setNumberReturn,
      othersReturn,
      setOthersReturn,
      wireReturn,
      setWireReturn,
      changeWireReturn,
      setChangeWireReturn,
      materialChangeReturn,
      setMaterialChangeReturn,
      numberChangeReturn,
      setNumberChangeReturn,
      othersChangeReturn,
      setOthersChangeReturn,
      keepThreadReturn,
      setKeepThreadReturn,
      keepReturn,
      setKeepReturn,
      ativationReturn,
      setAtivationReturn,
      cimentReturn,
      setCimentReturn,
      cimentBandReturn,
      setCimentBandReturn,
      glueReturn,
      setGlueReturn,
      glueTubReturn,
      setGlueTubReturn,
      repReturn,
      setRepReturn,
      repOrRecReturn,
      setRepOrRecReturn,
      repOrRecBraceReturn,
      setRepOrRecBraceReturn,
      complementReturn,
      setComplementReturn,
      compReturn,
      setCompReturn,
      othersProceduresReturn,
      setOthersProceduresReturn,
      valueOthersReturn,
      setValueOthersReturn,
      othersReferralsReturn,
      setOthersReferralsReturn,
      valueEncReturn,
      setValueEncReturn,
      mostImportantType,
      setMostImportantType,
      bandaExists,
      setBandaExists,
      bandaId,
      setBandaId,

      selectedDateBand,
      selectedHourBand,
      selectedTimeBand,
      handleDateChangeBand,
      handleTimeChangeBand,
      handleHourChangeBand,

      selectedProceduresId,
      setSelectedProceduresId,

      onlyBand,
      setOnlyBand,

      separatorId,
      setSeparatorId,

      justify01,
      setJustify01,
      justify02,
      setJustify02,
      justify03,
      setJustify03,
      justify04,
      setJustify04,
      justify05,
      setJustify05,
      justify06,
      setJustify06,
      justify07,
      setJustify07,
      justify08,
      setJustify08,
      justify09,
      setJustify09,

      informateRegion,
      setInformateRegion,
      realize,
      setRealize,
      justification,
      setJustification,
      realizeContent,
      setRealizeContent,
      realizeAparat,
      setRealizeAparat,
      realizeEruption,
      setRealizeEruption,
      realizeIntermaxilary,
      setRealizeIntermaxilary,
      realizeCollage,
      setRealizeCollage,
      realizeRebonding,
      setRealizeRebonding,
      realizeBite,
      setRealizeBite,
      route,
      setRoute,
      justifyRoute,
      setJustifyRoute,
      realizeRelease,
      setRealizeRelease,
      justificationContent,
      setJustificationContent,
      informateRegionContent,
      setInformateRegionContent,
      justificationAparat,
      setJustificationAparat,
      justificationEruption,
      setJustificationEruption,
      informateRegionEruption,
      setInformateRegionEruption,
      justificationIntermaxilary,
      setJustificationIntermaxilary,
      justificationCollage,
      setJustificationCollage,
      justificationRebonding,
      setJustificationRebonding,
      justificationBite,
      setJustificationBite,
      justificationRelease,
      setJustificationRelease,
      currentProcedureIndex,
      setCurrentProcedureIndex,

      odontogramaComplementReturn,
      setOdontogramaComplementReturn,
      odontogramaCollageReturn,
      setOdontogramaCollageReturn,
      odontogramaCimentReturn,
      setOdontogramaCimentReturn,

      proceduresLastAttendance,
      setProceduresLastAttendance,

      currentProcedureIndexReturn,
      setCurrentProcedureIndexReturn,

      showQuestionsReturn,
      setShowQuestionsReturn,

      odontogramaRemote,
      setOdontogramaRemote,
      odontogramaInstall,
      setOdontogramaInstall,
      odontogramaButton,
      setOdontogramaButton,
      odontogramaIntermax,
      setOdontogramaIntermax,
      odontogramaLevant,
      setOdontogramaLevant,

      justificationReturn,
      setJustificationReturn,
      informateRegionReturn,
      setInformateRegionReturn,
      realizeReturn,
      setRealizeReturn,
      realizeContentReturn,
      setRealizeContentReturn,
      realizeAparatReturn,
      setRealizeAparatReturn,
      realizeEruptionReturn,
      setRealizeEruptionReturn,
      realizeIntermaxilaryReturn,
      setRealizeIntermaxilaryReturn,
      realizeCollageReturn,
      setRealizeCollageReturn,
      realizeRebondingReturn,
      setRealizeRebondingReturn,
      realizeBiteReturn,
      setRealizeBiteReturn,
      routeReturn,
      setRouteReturn,
      justifyRouteReturn,
      setJustifyRouteReturn,
      realizeReleaseReturn,
      setRealizeReleaseReturn,
      justificationContentReturn,
      setJustificationContentReturn,
      informateRegionContentReturn,
      setInformateRegionContentReturn,
      justificationAparatReturn,
      setJustificationAparatReturn,
      justificationEruptionReturn,
      setJustificationEruptionReturn,
      informateRegionEruptionReturn,
      setInformateRegionEruptionReturn,
      justificationIntermaxilaryReturn,
      setJustificationIntermaxilaryReturn,
      justificationCollageReturn,
      setJustificationCollageReturn,
      justificationRebondingReturn,
      setJustificationRebondingReturn,
      justificationBiteReturn,
      setJustificationBiteReturn,
      justificationReleaseReturn,
      setJustificationReleaseReturn,
      odontogramaRemoteReturn,
      setOdontogramaRemoteReturn,
      odontogramaInstallReturn,
      setOdontogramaInstallReturn,
      odontogramaButtonReturn,
      setOdontogramaButtonReturn,
      odontogramaIntermaxReturn,
      setOdontogramaIntermaxReturn,
      odontogramaLevantReturn,
      setOdontogramaLevantReturn,
      odontogramaPlanningAparat,
      setOdontogramaPlanningAparat,
      odontogramaPlanningAparatReturn,
      setOdontogramaPlanningAparatReturn,
      braceTypeMaintenance,
      setBraceTypeMaintenance,
      repOrRecProcedure,
      setRepOrRecProcedure,
      repOrRecBraceProcedure,
      setRepOrRecBraceProcedure,
      repProcedure,
      setRepProcedure,
      painPrescriptionNeededMaintenance,
      setPainPrescriptionNeededMaintenance,
      informedBasicCareMaintenance,
      setInformedBasicCareMaintenance,
      materialProcedureMaintenance,
      setMaterialProcedureMaintenance,
      numberProcedureMaintenance,
      setNumberProcedureMaintenance,
      othersProceduresFixedMaintenance,
      setOthersProceduresFixedMaintenance,
      basicCareObservationsMaintenance,
      setBasicCareObservationsMaintenance,

      braceTypeMaintenanceReturn,
      setBraceTypeMaintenanceReturn,
      repOrRecProcedureReturn,
      setRepOrRecProcedureReturn,
      repOrRecBraceProcedureReturn,
      setRepOrRecBraceProcedureReturn,
      repProcedureReturn,
      setRepProcedureReturn,
      painPrescriptionNeededMaintenanceReturn,
      setPainPrescriptionNeededMaintenanceReturn,
      informedBasicCareMaintenanceReturn,
      setInformedBasicCareMaintenanceReturn,
      materialProcedureMaintenanceReturn,
      setMaterialProcedureMaintenanceReturn,
      numberProcedureMaintenanceReturn,
      setNumberProcedureMaintenanceReturn,
      othersProceduresFixedMaintenanceReturn,
      setOthersProceduresFixedMaintenanceReturn,
      basicCareObservationsMaintenanceReturn,
      setBasicCareObservationsMaintenanceReturn,
      odontogramaSeparator,
      setOdontogramaSeparator,
      braceMovel,
      setBraceMovel,
      relizeMoldage,
      setRealizeMoldage,
      odontogramaRepOrRec,
      setOdontogramaRepOrRec,
      showOtherProcedureScreen,
      setShowOtherProcedureScreen,
      hoursTime,
      setHoursTime,
      wireProcedures,
      setWireProcedures,
      changeWireProcedures,
      setChangeWireProcedures,
      materialChangeProcedures,
      setMaterialChangeProcedures,
      numberChangeProcedures,
      setNumberChangeProcedures,
      othersChangeProcedures,
      setOthersChangeProcedures,

      keepThreadProcedures,
      setKeepThreadProcedures,
      keepProcedures,
      setKeepProcedures,
      ativationProcedures,
      setAtivationProcedures,

      bestTimeBand,
      setBestTimeBand,

      odontogramaChangeWire,
      setOdontogramaChangeWire,

      otherProceduresValueProcedures,
      setOtherProceduresValueProcedures,

      valueReferralsProcedures,
      setValueReferralsProcedures,

      receiveHigh,
      setReceiveHigh,

      materialColaInf,
      setMaterialColaInf,
      numberColaInf,
      setNumberColaInf,
      othersColaInf,
      setOthersColaInf,

      materialChangeInf,
      setMaterialChangeInf,
      numberChangeInf,
      setNumberChangeInf,
      othersChangeInf,
      setOthersChangeInf,

      materialReturnInf,
      setMaterialReturnInf,
      numberReturnInf,
      setNumberReturnInf,
      othersReturnInf,
      setOthersReturnInf,

      materialChangeReturnInf,
      setMaterialChangeReturnInf,
      numberChangeReturnInf,
      setNumberChangeReturnInf,
      othersChangeReturnInf,
      setOthersChangeReturnInf,

      materialChangeProceduresInf,
      setMaterialChangeProceduresInf,
      numberChangeProceduresInf,
      setNumberChangeProceduresInf,
      othersChangeProceduresInf,
      setOthersChangeProceduresInf,
      allObservationsPlanning,
      setAllObservationsPlanning,
      allObservationsProcedure,
      setAllObservationsProcedure,
      otherProceduresValueSecondConsult,
      setOtherProceduresValueSecondConsult,
      attachment,
      setAttachment,
      changeAttachment,
      setChangeAttachment,
      odontogramaAttachment,
      setOdontogramaAttachment,
      realizeIPR,
      setRealizeIPR,
      odontogramaIPR,
      setOdontogramaIPR,
      orientInvisible,
      setOrientInvisible,
      motiveNoOrient,
      setMotiveNoOrient,
      orientPeriod,
      setOrientPeriod,
      motivePeriod,
      setMotivePeriod,
      daysPeriod,
      setDaysPeriod,
      plates,
      setPlates,
      elasticRequired,
      setElasticRequired,
      attachmentExchange,
      setAttachmentExchange,
      changeAttachmentExchange,
      setChangeAttachmentExchange,
      odontogramaAttachmentExchange,
      setOdontogramaAttachmentExchange,
      realizeIPRExchange,
      setRealizeIPRExchange,
      bracketsNumber,
      setBracketsNumber,
      keepObservation,
      setKeepObservation,
      keepDescription,
      setKeepDescription,
    }),
    [
      step,
      setStep,
      patientObj,
      selectedOptions,
      handleOptionChange,
      currentQuestionIndex,
      answers,
      handleAnswerChange,
      observations,
      errors,
      handleObservationChange,
      stepDiagnosis,
      setStepDiagnosis,
      btnOrtognatica,
      setBtnOrtognatica,
      btnCirurgy,
      setBtnCirurgy,
      btnOthers,
      setBtnOthers,
      btnPatients,
      setBtnPatients,
      braceComplement,
      setBraceComplement,
      brace,
      setBrace,
      checkedState,
      handleCheckboxChange,
      selectedDate,
      selectedHour,
      selectedTime,
      handleDateChange,
      handleTimeChange,
      handleHourChange,
      othersProcedures,
      setOthersProcedures,
      othersReferrals,
      setOthersReferrals,
      regionDescription,
      setRegionDescription,
      valueOthers,
      setValueOthers,
      valueEnc,
      setValueEnc,
      aparate,
      setAparate,
      wire,
      setWire,
      keepThread,
      setKeepThread,
      ciment,
      setCiment,
      glue,
      setGlue,
      complement,
      setComplement,
      rep,
      setRep,
      aparateBrace,
      setAparateBrace,
      changeWire,
      setChangeWire,
      keep,
      setKeep,
      cimentBand,
      setCimentBand,
      glueTube,
      setGlueTube,
      comp,
      setComp,
      repOrRec,
      setRepOrRec,
      repOrRecBrace,
      setRepOrRecBrace,
      needsBand,
      setNeedsBand,
      needsSeparator,
      setNeedsSeparator,
      performMolding,
      setPerformMolding,
      installSeparator,
      setInstallSeparator,
      moldingJustification,
      setMoldingJustification,
      separatorJustification,
      setSeparatorJustification,
      braceType,
      setBraceType,
      painPrescriptionNeeded,
      setPainPrescriptionNeeded,
      informedBasicCare,
      setInformedBasicCare,
      deliveredTreatmentManual,
      setDeliveredTreatmentManual,
      advisedWireAndWax,
      setAdvisedWireAndWax,
      additionalDevice,
      setAdditionalDevice,
      hasComplement,
      setHasComplement,
      hasOtherProcedures,
      setHasOtherProcedures,
      hasOtherReferrals,
      setHasOtherReferrals,
      otherProceduresValue,
      setOtherProceduresValue,
      referralValue,
      setReferralValue,
      regionDescriptionProcedure,
      setRegionDescriptionProcedure,
      showPainPrescriptionNeeded,
      setShowPainPrescriptionNeeded,
      showInformedBasicCare,
      setShowInformedBasicCare,
      showDeliveredTreatmentManual,
      setShowDeliveredTreatmentManual,
      showAdvisedWireAndWax,
      setShowAdvisedWireAndWax,
      showAdditionalDevice,
      setShowAdditionalDevice,
      diagnosisDescription,
      setDiagnosisDescription,
      materialProcedure,
      setMaterialProcedure,
      numberProcedure,
      setNumberProcedure,
      othersProceduresFixed,
      setOthersProceduresFixed,
      materialCola,
      setMaterialCola,
      numberCola,
      setNumberCola,
      othersCola,
      setOthersCola,
      materialChange,
      setMaterialChange,
      numberChange,
      setNumberChange,
      othersChange,
      setOthersChange,
      ativation,
      setAtivation,
      odontogramaComplement,
      setOdontogramaComplement,
      odontogramaCollage,
      setOdontogramaCollage,
      odontogramaCiment,
      setOdontogramaCiment,
      bestTime,
      setBestTime,
      setSelectedHour,
      videoClicked,
      setVideoClicked,
      painPrescriptionObservations,
      setPainPrescriptionObservations,
      basicCareObservations,
      setBasicCareObservations,
      treatmentManualObservations,
      setTreatmentManualObservations,
      wireAndWaxObservations,
      setWireAndWaxObservations,
      errorPainPrescription,
      setErrorPainPrescription,
      errorBasicCare,
      setErrorBasicCare,
      errorTreatmentManual,
      setErrorTreatmentManual,
      errorWireAndWax,
      setErrorWireAndWax,
      odontogramaAparat,
      setOdontogramaAparat,
      questionsPlanning,
      aparateReturn,
      setAparateReturn,
      aparateBraceReturn,
      setAparateBraceReturn,
      materialReturn,
      setMaterialReturn,
      numberReturn,
      setNumberReturn,
      othersReturn,
      setOthersReturn,
      wireReturn,
      setWireReturn,
      changeWireReturn,
      setChangeWireReturn,
      materialChangeReturn,
      setMaterialChangeReturn,
      numberChangeReturn,
      setNumberChangeReturn,
      othersChangeReturn,
      setOthersChangeReturn,
      keepThreadReturn,
      setKeepThreadReturn,
      keepReturn,
      setKeepReturn,
      ativationReturn,
      setAtivationReturn,
      cimentReturn,
      setCimentReturn,
      cimentBandReturn,
      setCimentBandReturn,
      glueReturn,
      setGlueReturn,
      glueTubReturn,
      setGlueTubReturn,
      repReturn,
      setRepReturn,
      repOrRecReturn,
      setRepOrRecReturn,
      repOrRecBraceReturn,
      setRepOrRecBraceReturn,
      complementReturn,
      setComplementReturn,
      compReturn,
      setCompReturn,
      othersProceduresReturn,
      setOthersProceduresReturn,
      valueOthersReturn,
      setValueOthersReturn,
      othersReferralsReturn,
      setOthersReferralsReturn,
      valueEncReturn,
      setValueEncReturn,
      mostImportantType,
      setMostImportantType,
      bandaExists,
      setBandaExists,
      bandaId,
      setBandaId,
      selectedDateBand,
      selectedHourBand,
      selectedTimeBand,
      handleDateChangeBand,
      handleTimeChangeBand,
      handleHourChangeBand,

      selectedProceduresId,
      setSelectedProceduresId,

      onlyBand,
      setOnlyBand,

      separatorId,
      setSeparatorId,

      justify01,
      setJustify01,
      justify02,
      setJustify02,
      justify03,
      setJustify03,
      justify04,
      setJustify04,
      justify05,
      setJustify05,
      justify06,
      setJustify06,
      justify07,
      setJustify07,
      justify08,
      setJustify08,
      justify09,
      setJustify09,

      informateRegion,
      setInformateRegion,
      realize,
      setRealize,
      justification,
      setJustification,
      realizeContent,
      setRealizeContent,
      realizeAparat,
      setRealizeAparat,
      realizeEruption,
      setRealizeEruption,
      realizeIntermaxilary,
      setRealizeIntermaxilary,
      realizeCollage,
      setRealizeCollage,
      realizeRebonding,
      setRealizeRebonding,
      realizeBite,
      setRealizeBite,
      route,
      setRoute,
      justifyRoute,
      setJustifyRoute,
      realizeRelease,
      setRealizeRelease,
      justificationContent,
      setJustificationContent,
      informateRegionContent,
      setInformateRegionContent,
      justificationAparat,
      setJustificationAparat,
      justificationEruption,
      setJustificationEruption,
      informateRegionEruption,
      setInformateRegionEruption,
      justificationIntermaxilary,
      setJustificationIntermaxilary,
      justificationCollage,
      setJustificationCollage,
      justificationRebonding,
      setJustificationRebonding,
      justificationBite,
      setJustificationBite,
      justificationRelease,
      setJustificationRelease,
      currentProcedureIndex,
      setCurrentProcedureIndex,
      odontogramaComplementReturn,
      setOdontogramaComplementReturn,
      odontogramaCollageReturn,
      setOdontogramaCollageReturn,
      odontogramaCimentReturn,
      setOdontogramaCimentReturn,

      proceduresLastAttendance,
      setProceduresLastAttendance,

      currentProcedureIndexReturn,
      setCurrentProcedureIndexReturn,

      showQuestionsReturn,
      setShowQuestionsReturn,

      odontogramaRemote,
      setOdontogramaRemote,
      odontogramaInstall,
      setOdontogramaInstall,
      odontogramaButton,
      setOdontogramaButton,
      odontogramaIntermax,
      setOdontogramaIntermax,
      odontogramaLevant,
      setOdontogramaLevant,

      justificationReturn,
      setJustificationReturn,
      informateRegionReturn,
      setInformateRegionReturn,
      realizeReturn,
      setRealizeReturn,
      realizeContentReturn,
      setRealizeContentReturn,
      realizeAparatReturn,
      setRealizeAparatReturn,
      realizeEruptionReturn,
      setRealizeEruptionReturn,
      realizeIntermaxilaryReturn,
      setRealizeIntermaxilaryReturn,
      realizeCollageReturn,
      setRealizeCollageReturn,
      realizeRebondingReturn,
      setRealizeRebondingReturn,
      realizeBiteReturn,
      setRealizeBiteReturn,
      routeReturn,
      setRouteReturn,
      justifyRouteReturn,
      setJustifyRouteReturn,
      realizeReleaseReturn,
      setRealizeReleaseReturn,
      justificationContentReturn,
      setJustificationContentReturn,
      informateRegionContentReturn,
      setInformateRegionContentReturn,
      justificationAparatReturn,
      setJustificationAparatReturn,
      justificationEruptionReturn,
      setJustificationEruptionReturn,
      informateRegionEruptionReturn,
      setInformateRegionEruptionReturn,
      justificationIntermaxilaryReturn,
      setJustificationIntermaxilaryReturn,
      justificationCollageReturn,
      setJustificationCollageReturn,
      justificationRebondingReturn,
      setJustificationRebondingReturn,
      justificationBiteReturn,
      setJustificationBiteReturn,
      justificationReleaseReturn,
      setJustificationReleaseReturn,
      odontogramaRemoteReturn,
      setOdontogramaRemoteReturn,
      odontogramaInstallReturn,
      setOdontogramaInstallReturn,
      odontogramaButtonReturn,
      setOdontogramaButtonReturn,
      odontogramaIntermaxReturn,
      setOdontogramaIntermaxReturn,
      odontogramaLevantReturn,
      setOdontogramaLevantReturn,
      odontogramaPlanningAparat,
      setOdontogramaPlanningAparat,
      odontogramaPlanningAparatReturn,
      setOdontogramaPlanningAparatReturn,
      braceTypeMaintenance,
      setBraceTypeMaintenance,
      repOrRecProcedure,
      setRepOrRecProcedure,
      repOrRecBraceProcedure,
      setRepOrRecBraceProcedure,
      repProcedure,
      setRepProcedure,
      painPrescriptionNeededMaintenance,
      setPainPrescriptionNeededMaintenance,
      informedBasicCareMaintenance,
      setInformedBasicCareMaintenance,
      materialProcedureMaintenance,
      setMaterialProcedureMaintenance,
      numberProcedureMaintenance,
      setNumberProcedureMaintenance,
      othersProceduresFixedMaintenance,
      setOthersProceduresFixedMaintenance,
      basicCareObservationsMaintenance,
      setBasicCareObservationsMaintenance,

      braceTypeMaintenanceReturn,
      setBraceTypeMaintenanceReturn,
      repOrRecProcedureReturn,
      setRepOrRecProcedureReturn,
      repOrRecBraceProcedureReturn,
      setRepOrRecBraceProcedureReturn,
      repProcedureReturn,
      setRepProcedureReturn,
      painPrescriptionNeededMaintenanceReturn,
      setPainPrescriptionNeededMaintenanceReturn,
      informedBasicCareMaintenanceReturn,
      setInformedBasicCareMaintenanceReturn,
      materialProcedureMaintenanceReturn,
      setMaterialProcedureMaintenanceReturn,
      numberProcedureMaintenanceReturn,
      setNumberProcedureMaintenanceReturn,
      othersProceduresFixedMaintenanceReturn,
      setOthersProceduresFixedMaintenanceReturn,
      basicCareObservationsMaintenanceReturn,
      setBasicCareObservationsMaintenanceReturn,
      odontogramaSeparator,
      setOdontogramaSeparator,
      braceMovel,
      setBraceMovel,
      relizeMoldage,
      setRealizeMoldage,
      odontogramaRepOrRec,
      setOdontogramaRepOrRec,
      showOtherProcedureScreen,
      setShowOtherProcedureScreen,
      hoursTime,
      setHoursTime,
      wireProcedures,
      setWireProcedures,
      changeWireProcedures,
      setChangeWireProcedures,
      materialChangeProcedures,
      setMaterialChangeProcedures,
      numberChangeProcedures,
      setNumberChangeProcedures,
      othersChangeProcedures,
      setOthersChangeProcedures,

      keepThreadProcedures,
      setKeepThreadProcedures,
      keepProcedures,
      setKeepProcedures,
      ativationProcedures,
      setAtivationProcedures,

      bestTimeBand,
      setBestTimeBand,

      odontogramaChangeWire,
      setOdontogramaChangeWire,

      otherProceduresValueProcedures,
      setOtherProceduresValueProcedures,

      valueReferralsProcedures,
      setValueReferralsProcedures,

      receiveHigh,
      setReceiveHigh,

      materialColaInf,
      setMaterialColaInf,
      numberColaInf,
      setNumberColaInf,
      othersColaInf,
      setOthersColaInf,

      materialChangeInf,
      setMaterialChangeInf,
      numberChangeInf,
      setNumberChangeInf,
      othersChangeInf,
      setOthersChangeInf,

      materialReturnInf,
      setMaterialReturnInf,
      numberReturnInf,
      setNumberReturnInf,
      othersReturnInf,
      setOthersReturnInf,

      materialChangeReturnInf,
      setMaterialChangeReturnInf,
      numberChangeReturnInf,
      setNumberChangeReturnInf,
      othersChangeReturnInf,
      setOthersChangeReturnInf,

      materialChangeProceduresInf,
      setMaterialChangeProceduresInf,
      numberChangeProceduresInf,
      setNumberChangeProceduresInf,
      othersChangeProceduresInf,
      setOthersChangeProceduresInf,

      allObservationsPlanning,
      setAllObservationsPlanning,
      allObservationsProcedure,
      setAllObservationsProcedure,
      otherProceduresValueSecondConsult,
      setOtherProceduresValueSecondConsult,
      attachment,
      setAttachment,
      changeAttachment,
      setChangeAttachment,
      odontogramaAttachment,
      setOdontogramaAttachment,
      realizeIPR,
      setRealizeIPR,
      odontogramaIPR,
      setOdontogramaIPR,
      orientInvisible,
      setOrientInvisible,
      motiveNoOrient,
      setMotiveNoOrient,
      orientPeriod,
      setOrientPeriod,
      motivePeriod,
      setMotivePeriod,
      daysPeriod,
      setDaysPeriod,
      plates,
      setPlates,
      elasticRequired,
      setElasticRequired,
      attachmentExchange,
      setAttachmentExchange,
      changeAttachmentExchange,
      setChangeAttachmentExchange,
      odontogramaAttachmentExchange,
      setOdontogramaAttachmentExchange,
      realizeIPRExchange,
      setRealizeIPRExchange,
      bracketsNumber,
      setBracketsNumber,
      keepObservation,
      setKeepObservation,
      keepDescription,
      setKeepDescription,
    ]
  );

  return (
    <MaintenanceContext.Provider value={value}>
      {children}
    </MaintenanceContext.Provider>
  );
};

const useMaintenance = (): IMaintenanceContext =>
  React.useContext(MaintenanceContext);

export { MaintenanceContext, MaintenanceProvider, useMaintenance };
